import React, { useEffect } from 'react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';
import {
  IonRouterOutlet,
  IonSplitPane,
} from '@ionic/react';

import Menu from './components/Menu';
import Home from './pages/Home';
import Movies from './pages/Movies';
import TVShows from './pages/TVShows';
import Evergreen from './pages/Evergreen';
import Trailers from './pages/Trailers';
import WatchList from './pages/WatchList';
import AboutUs from './pages/AboutUs';
import Detail from './pages/Detail';
import Login from './pages/Login';
import PageNotFound from './pages/PageNotFound';
// import AuthDataProvider from './contexts/AuthContext';
import Profile from './pages/Profile';
import Help from './pages/Help';
import List from './pages/List';
import PrivacyPolicy from './pages/PrivacyPolicy';

const BingeRouter: React.FC = () => {

    const token = localStorage.getItem('BingeSearchToken');

    useEffect(() => {
        // clean up controller
        let isSubscribed = true;
        if (token) {
            console.log(`Token found in BingeRouter ${token}`);
        } else {
            console.log(`No Token found in BingeRouter`);
        }
        // cancel subscription to useEffect
        return () => { (isSubscribed = false) };
    }, [token?.length])

    return (
        <IonReactRouter>
            <IonSplitPane when="(min-width: 768px)" contentId="bingesearch">
                {/*--  the side menu with same  contentId  --*/}
                <Menu />
                {/*-- the main content with router with same contentId --*/}
                <IonRouterOutlet id="bingesearch">
                    <Route path="/home" exact={true}>
                        <Home />
                    </Route>
                    <Route path="/movies" exact={true}>
                        <Movies />
                    </Route>
                    <Route path="/tvshows" exact={true}>
                        <TVShows />
                    </Route>
                    <Route path="/evergreen" exact={true}>
                        <Evergreen />
                    </Route>
                    <Route path="/home/:item" exact={true}>
                        <Detail />
                    </Route>
                    <Route path="/movies/:item" exact={true}>
                        <Detail />
                    </Route>
                    <Route path="/tvshows/:item" exact={true}>
                        <Detail />
                    </Route>
                    <Route path="/evergreen/:item" exact={true}>
                        <Detail />
                    </Route>
                    <Route path="/home/list/category/:item" exact={true}>
                        <Detail />
                    </Route>
                    <Route path="/movies/list/category/:item" exact={true}>
                        <Detail />
                    </Route>
                    <Route path="/tvshows/list/category/:item" exact={true}>
                        <Detail />
                    </Route>
                    <Route path="/home/list/:category" exact={true}>
                        <List />
                    </Route>
                    <Route path="/movies/list/:category" exact={true}>
                        <List />
                    </Route>
                    <Route path="/tvshows/list/:category" exact={true}>
                        <List />
                    </Route>
                    <Route path="/detail" exact={true}>
                        <Detail />
                    </Route>
                    <Route path="/trailers" exact={true}>
                        <Trailers />
                    </Route>
                    <Route path="/aboutus" exact={true}>
                        <AboutUs />
                    </Route>
                    <Route path="/privacypolicy" exact={true}>
                        <PrivacyPolicy />
                    </Route>
                    <Route path="/help" exact={true}>
                        <Help />
                    </Route>
                    <Route path="/login" exact={true}>
                        <Login />
                    </Route>
                    <Route path="/profile" render={() => token ? <Profile /> : <Redirect to="/login" />} exact={true} />
                    <Route path="/bingelist" render={() => token ? <WatchList /> : <Redirect to="/login" />} exact={true} />
                    <Route path="/" exact={true}>
                        <Redirect to="/home" />
                    </Route>
                    <Route path="">
                        <PageNotFound />
                    </Route>
                </IonRouterOutlet>
            </IonSplitPane>
        </IonReactRouter>
    );
};

export default BingeRouter;


