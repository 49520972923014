import { IonContent, IonPage, IonLoading, IonGrid, IonRow, IonCol, IonToast, IonLabel, IonIcon } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import './Home.css';
import AppToolBar from '../components/ToolBar';
import axios from 'axios';
import { closeCircle } from 'ionicons/icons';
import { IMovieData } from '../interfaces/MovieInterface';
import { BingeBaseURL, logOut } from '../BingeEnvConfig';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BingeFooter from '../components/BingeFooter';
const WatchList: React.FC = () => {

  // let { from } = location.state || { from: { pathname: "/" } };
  // let login = () => {
  //   fakeAuth.authenticate(() => {
  //     history.replace(from);
  //   });
  // };
  const { t } = useTranslation();
  let history = useHistory();
  const token = localStorage.getItem('BingeSearchToken');
  const username = localStorage.getItem('BingeSearchUsername');
  // console.log(`Token found in WatchList ${token}`);
  const [showToast1, setShowToast1] = useState(false);
  const [showToast2, setShowToast2] = useState(false);
  const [listData, setListData] = useState<IMovieData[]>([]);
  const [showLoading, setShowLoading] = useState(false);
  const [error, setError] = useState(null);
  const [listItemLength, setListItemLength] = useState(0);

  useEffect(() => {
    // clean up controller
    let isSubscribed = true;
    try {
      getYourWatchList();
    } catch (err:any) {
      setError(err);
      setShowLoading(false);
    }
    // cancel subscription to useEffect
    return () => { (isSubscribed = false) };
  }, [listItemLength]);

  const getYourWatchList = async () => {
    setShowLoading(true);
    const result = await axios({
      method: 'GET',
      url: BingeBaseURL + 'user/watchlist',
      params: {
        username: JSON.parse(username as string)
      },
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json;charset=utf-8',
        'Cache-Control': 'no-cache, no-store, must-revalidate', // HTTP 1.1.
        'Pragma': 'no-cache',
        'Expires': '0',
        'Authorization': JSON.parse(token as string)
      }
    });
    console.log(result);
    if (result.status === 200) {
      setListData(result.data);
      setShowLoading(false);
      setListItemLength(result.data.length);
    }
    // token expiration
    else if (result.status === 204) {
      setShowLoading(false);
      setShowToast1(true);
      logOut();
    }
  }

  const removeFromWatchList = async (title: string, type: string) => {
    const result = await axios({
      method: 'DELETE',
      url: BingeBaseURL + 'user/watchlist',
      data: {
        username: JSON.parse(username as string),
        watchlist: {
          title: title,
          type: type
        }
      },
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json;charset=utf-8',
        'Cache-Control': 'no-cache, no-store, must-revalidate', // HTTP 1.1.
        'Pragma': 'no-cache',
        'Expires': '0',
        'Authorization': JSON.parse(token as string)
      }
    });
    if (result.status === 200) {
      setListItemLength(listData.length - 1);
    }
  };

  if (listItemLength === 0) {
    return (
      <React.Fragment>
        <AppToolBar />
        <IonContent>
          <div className='error-box'>
            <p>{t('DontHaveWatchlist')}</p>
          </div>
        </IonContent>
      </React.Fragment>
    );
  }

  if (error) {
    return (
      <React.Fragment>
        <IonPage>
          <AppToolBar />
          <IonContent>
            <div className='error-box'>
              <p>{t('ErrorFetchingContent')}<span role='img' aria-label='sad'>😥</span>. This is due to - {JSON.stringify(error)}.</p>
              <p>{t('FollowInstructions')}:</p>
              <ul>
                <li>{t('CheckInternetConnection')}.</li>
                <li>{t('PullPageRefresh')}.</li>
                <li>{t('RefreshBrowserPage')}.</li>
              </ul>
              <p>{t('TryAgainSometime')}.</p>
            </div>
          </IonContent>
          <BingeFooter />
        </IonPage>
      </React.Fragment>
    );
  }

  return (
    <IonPage>
      <AppToolBar />
      <IonContent>
        <IonLoading
          isOpen={showLoading}
          backdropDismiss
          spinner='bubbles'
          message={'Bringing your BingeList...'}
          cssClass='ion-loader-design'
        />
        <IonToast
          isOpen={showToast1}
          onDidDismiss={() => setShowToast1(false)}
          message="Your session is ended! Please login again."
          duration={5000}
          position="bottom"
          color='warning'
        />
        <IonToast
          isOpen={showToast2}
          onDidDismiss={() => setShowToast2(false)}
          message="Your favorite has been removed from your BingeList!"
          duration={2000}
          position="bottom"
          color='primary'
        />

        <IonGrid>
          <IonLabel className='section'>{t('EnjoyYourWatchList')}</IonLabel>
          <IonRow>
            {(listData as IMovieData[])?.map((item: IMovieData, idx: number) => (
              <IonCol key={idx} sizeXs='4' sizeSm='3' sizeMd='2'>
                <IonIcon className='watchlist-icon' icon={closeCircle} onClick={() => {
                  removeFromWatchList(item?.title, item?.type);
                  setShowToast2(true);
                }}></IonIcon>
                <img src={item?.poster} alt="images" className='square-corner' height='120px' width='180px' onClick={(e) => {
                  e.preventDefault();
                  history.push({
                    pathname: '/detail',
                    state: { detail: item }
                  });
                }} />
                <div className='source-style'>{item?.source}</div>
              </IonCol>
            ))}
          </IonRow>
        </IonGrid>

      </IonContent>
      <BingeFooter />
    </IonPage>
  );
};

export default WatchList;