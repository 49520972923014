const ImageComponent = ({
  imageUrl,
  alt,
  className = "",
  width = "",
  height = "",
}) => {
  const handleError = (event) => {
    event.target.src = getRandomPlaceholderImage();
    event.target.alt = "BingeSearch";
  };

  const getRandomPlaceholderImage = () => {
    const placeHolderImages = ["assets/images/movie1.jpg","assets/images/movie2.jpg","assets/images/movie3.jpg"]; 
    return placeHolderImages[Math.floor((Math.random() * placeHolderImages.length))];
  }

  return (
    <img
      src={imageUrl}
      onError={handleError}
      loading="lazy"
      alt={alt}
      className={className}
      width={width}
      height={height}
    />
  );
};

export default ImageComponent;
