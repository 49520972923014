
import { IonContent, IonPage, IonLoading, IonRefresher, IonRefresherContent, IonRow, IonCol, IonSlides, IonLabel, IonSlide, IonGrid, IonButton, IonIcon } from '@ionic/react';
import React from 'react';
import './Evergreen.css';
import AppToolBar from '../components/ToolBar';
import { useBingeAxios } from '../BingeEnvConfig';
import { arrowRedoCircleOutline, chevronDownCircleOutline } from 'ionicons/icons';
import { IMovieData } from '../interfaces/MovieInterface';
import { slideOpts } from './Home';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import BingeFooter from '../components/BingeFooter';
import { ITVShowData } from '../interfaces/TVShowInterface';
import ImageComponent from '../components/ImageComponent';

const Evergreen: React.FC = () => {

  // let history = useHistory();
  const { t } = useTranslation();
  const [{ data: getTopIMDBMovieData, loading: isTopIMDBMovieLoading, error: getTopIMDBMovieError }, topIMDBMoviesRefetch] = useBingeAxios('movies/topimdbrated?limit=true');
  const [{ data: getTopIMDBShowsData, loading: isTopIMDBShowsLoading, error: getTopIMDBShowsError }, topIMDBShowsRefetch] = useBingeAxios('tvshows/topimdbrated?limit=true');

  if (getTopIMDBMovieError && getTopIMDBShowsError) {
    return (
      <React.Fragment>
        <IonPage>
          <AppToolBar />
          <IonContent>
            <IonRefresher slot="fixed" onIonRefresh={(topIMDBMoviesRefetch || topIMDBShowsRefetch) as any}>
              <IonRefresherContent
                pullingIcon={chevronDownCircleOutline}
                pullingText="Pull to refresh"
                refreshingSpinner="circles"
                refreshingText="Refreshing...">
              </IonRefresherContent>
            </IonRefresher>
            <div className='error-box'>
              <p>{t('ErrorFetchingContent')}<span role='img' aria-label='sad'>😥</span>. This is due to - {JSON.stringify(getTopIMDBMovieError?.message)}.</p>
              <p>{t('FollowInstructions')}:</p>
              <ul>
                <li>{t('CheckInternetConnection')}.</li>
                <li>{t('PullPageRefresh')}.</li>
                <li>{t('RefreshBrowserPage')}.</li>
              </ul>
              <p>{t('TryAgainSometime')}.</p>
            </div>
          </IonContent>
          <BingeFooter />
        </IonPage>
      </React.Fragment>
    );
  }

  if (isTopIMDBMovieLoading || isTopIMDBShowsLoading) {
    return (
      <React.Fragment>
        <AppToolBar />
        <IonLoading
          isOpen={isTopIMDBMovieLoading || isTopIMDBShowsLoading}
          spinner='bubbles'
          showBackdrop={true}
          message={'Fetching Top Rated Movies & TV Shows for you...😍'}
          cssClass='ion-loader-design' />
      </React.Fragment>
    );
  }

  return (
    <IonPage>
      <AppToolBar />
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={(topIMDBMoviesRefetch || topIMDBShowsRefetch) as any}>
          <IonRefresherContent
            pullingIcon={chevronDownCircleOutline}
            pullingText="Pull to refresh"
            refreshingSpinner="circles"
            refreshingText="Refreshing...">
          </IonRefresherContent>
        </IonRefresher>


        <IonGrid>

          <IonRow>
            <IonCol>
              <IonLabel className='section'>{t('TopImdbMovies')}
                <IonButton fill='clear' size='small' routerLink="/movies/list/Top Movies" routerDirection="forward" >
                  <IonIcon className='explore-icon' icon={arrowRedoCircleOutline}></IonIcon>
                </IonButton>
              </IonLabel>
              <IonSlides class='slide-styles' scrollbar={true} options={slideOpts}>
                {(getTopIMDBMovieData as IMovieData[]).map((item: IMovieData, idx: number) => (
                  <IonSlide key={idx}>
                    <div>
                      <div className='slide-numbering'>{item.rank}</div>
                      <Link to={{ pathname: `/evergreen/${item.title}`, state: { detail: item } }} >
                        <ImageComponent imageUrl={item?.poster} alt="BingeSearchImage" className='square-corner'/>
                      </Link>
                      <div className='source-style'>{item?.source}</div>
                    </div>
                   
                  </IonSlide>
                ))}
              </IonSlides>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonLabel className='section'>{t('TopImdbShows')}
                <IonButton fill='clear' size='small' routerLink="/tvshows/list/Top TVShows" routerDirection="forward" >
                  <IonIcon className='explore-icon' icon={arrowRedoCircleOutline}></IonIcon>
                </IonButton>
              </IonLabel>
              <IonSlides class='slide-styles' scrollbar={true} options={slideOpts}>
                {(getTopIMDBShowsData as ITVShowData[]).map((item: ITVShowData, idx: number) => (
                  <IonSlide key={idx}>
                    <div>
                      <div className='slide-numbering'>{item.rank}</div>
                      <Link to={{ pathname: `/evergreen/${item.title}`, state: { detail: item } }} >
                        <ImageComponent imageUrl={item?.poster} alt="BingeSearchImage" className='square-corner'/>
                      </Link>
                      <div className='source-style'>{item?.source}</div>
                    </div>
                  </IonSlide>
                ))}
              </IonSlides>
            </IonCol>
          </IonRow>

        </IonGrid>

      </IonContent>
      <BingeFooter />
    </IonPage>
  );
};

export default Evergreen;
