
import { IonContent, IonPage, } from '@ionic/react';
import React from 'react';
// import { useParams } from 'react-router';
import './Trailers.css';
import AppToolBar from '../components/ToolBar';

const Trailers: React.FC = () => {

  // const { name } = useParams<{ name: string; }>();

  return (
    <IonPage>
    <AppToolBar />
    <IonContent>
      {/* <IonLoading
        isOpen={showLoading}
        backdropDismiss
        spinner='bubbles'
        onDidDismiss={() => setShowLoading(false)}
        //message={'Fetching latest movies for you...'},
        cssClass='ion-loader-design'
      /> */}
      </IonContent>
    </IonPage>
  );
};

export default Trailers;
