import React from 'react';
import { IonSlides, IonSlide, IonImg } from '@ionic/react';
import './Slideshow.css';
import { IMovieData } from '../interfaces/MovieInterface';
import { ITVShowData } from '../interfaces/TVShowInterface';
import { useBingeAxios } from '../BingeEnvConfig';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type SlideProps = {
    pageName: string
}

const Slideshow = ({ pageName }: SlideProps) => {

    let history = useHistory();
    const { t } = useTranslation();
    const [{ data: getLatestMovieData, error: getLatestMovieError }] = useBingeAxios('movies/latest');
    // console.log(getLatestMovieData);
    const [{ data: getLatestTVShowData, error: getLatestTVShowError }] = useBingeAxios('tvshows/latest');
    // console.log(getLatestTVShowData);
    let combineData;
    if (getLatestMovieData && getLatestTVShowData) {
        combineData = [...getLatestMovieData, ...getLatestTVShowData];
        // console.log(combineData);
    }


    const slideOpts = {
        slidesPerView: 1,
        spaceBetween: 10,
        autoplay: {
            delay: 2000,
            disableOnInteraction: false
        },
        coverflowEffect: {
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
        },
        on: {
            beforeInit() {
                const swiper = this;

                (swiper as any).classNames.push(`${(swiper as any).params.containerModifierClass}coverflow`);
                (swiper as any).classNames.push(`${(swiper as any).params.containerModifierClass}3d`);

                (swiper as any).params.watchSlidesProgress = true;
                (swiper as any).originalParams.watchSlidesProgress = true;
            },
            setTranslate() {
                const swiper = this;
                const {
                    width: swiperWidth, height: swiperHeight, slides, $wrapperEl, slidesSizesGrid, $
                } = (swiper as any);
                const params = (swiper as any).params.coverflowEffect;
                const isHorizontal = (swiper as any).isHorizontal();
                const transform$$1 = (swiper as any).translate;
                const center = isHorizontal ? -transform$$1 + (swiperWidth / 2) : -transform$$1 + (swiperHeight / 2);
                const rotate = isHorizontal ? params.rotate : -params.rotate;
                const translate = params.depth;
                // Each slide offset from center
                for (let i = 0, length = slides.length; i < length; i += 1) {
                    const $slideEl = slides.eq(i);
                    const slideSize = slidesSizesGrid[i];
                    const slideOffset = $slideEl[0].swiperSlideOffset;
                    const offsetMultiplier = ((center - slideOffset - (slideSize / 2)) / slideSize) * params.modifier;

                    let rotateY = isHorizontal ? rotate * offsetMultiplier : 0;
                    let rotateX = isHorizontal ? 0 : rotate * offsetMultiplier;
                    // var rotateZ = 0
                    let translateZ = -translate * Math.abs(offsetMultiplier);

                    let translateY = isHorizontal ? 0 : params.stretch * (offsetMultiplier);
                    let translateX = isHorizontal ? params.stretch * (offsetMultiplier) : 0;

                    // Fix for ultra small values
                    if (Math.abs(translateX) < 0.001) translateX = 0;
                    if (Math.abs(translateY) < 0.001) translateY = 0;
                    if (Math.abs(translateZ) < 0.001) translateZ = 0;
                    if (Math.abs(rotateY) < 0.001) rotateY = 0;
                    if (Math.abs(rotateX) < 0.001) rotateX = 0;

                    const slideTransform = `translate3d(${translateX}px,${translateY}px,${translateZ}px)  rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;

                    $slideEl.transform(slideTransform);
                    $slideEl[0].style.zIndex = -Math.abs(Math.round(offsetMultiplier)) + 1;
                    if (params.slideShadows) {
                        // Set shadows
                        let $shadowBeforeEl = isHorizontal ? $slideEl.find('.swiper-slide-shadow-left') : $slideEl.find('.swiper-slide-shadow-top');
                        let $shadowAfterEl = isHorizontal ? $slideEl.find('.swiper-slide-shadow-right') : $slideEl.find('.swiper-slide-shadow-bottom');
                        if ($shadowBeforeEl.length === 0) {
                            $shadowBeforeEl = (swiper as any).$(`<div class="swiper-slide-shadow-${isHorizontal ? 'left' : 'top'}"></div>`);
                            $slideEl.append($shadowBeforeEl);
                        }
                        if ($shadowAfterEl.length === 0) {
                            $shadowAfterEl = (swiper as any).$(`<div class="swiper-slide-shadow-${isHorizontal ? 'right' : 'bottom'}"></div>`);
                            $slideEl.append($shadowAfterEl);
                        }
                        if ($shadowBeforeEl.length) $shadowBeforeEl[0].style.opacity = offsetMultiplier > 0 ? offsetMultiplier : 0;
                        if ($shadowAfterEl.length) $shadowAfterEl[0].style.opacity = (-offsetMultiplier) > 0 ? -offsetMultiplier : 0;
                    }
                }

                // Set correct perspective for IE10
                if ((swiper as any).support.pointerEvents || (swiper as any).support.prefixedPointerEvents) {
                    const ws = $wrapperEl[0].style;
                    ws.perspectiveOrigin = `${center}px 50%`;
                }
            },
            setTransition(duration: any) {
                const swiper = this;
                (swiper as any).slides
                    .transition(duration)
                    .find('.swiper-slide-shadow-top, .swiper-slide-shadow-right, .swiper-slide-shadow-bottom, .swiper-slide-shadow-left')
                    .transition(duration);
            }
        },
        ionSlideTap: {

        },
        // Responsive breakpoints
        breakpoints: {
            340: {
                slidesPerView: 3,
                spaceBetween: 5
            },
            // when window width is >= 480px
            480: {
                slidesPerView: 3,
                spaceBetween: 20
            },
            // when window width is >= 768px
            768: {
                slidesPerView: 3,
                spaceBetween: 10
            },
            1024: {
                slidesPerView: 3,
                spaceBetween: 20
            }
        }
    }

    if (pageName === 'movies') {
        return (
            <React.Fragment>
                <IonSlides id='main-slideshow' class='slide-styles' pager={true} options={slideOpts}>
                    {(getLatestMovieData as IMovieData[])?.map((item: IMovieData, idx: number) => (
                        <IonSlide key={idx}>
                            <div>
                                <div className='slideshow-details'>{t('WatchNowOn')} {item?.source}</div>
                                <IonImg src={item?.poster} alt="images" className='slideshow-images' onClick={(e) => {
                                    e.preventDefault();
                                    history.push({
                                        pathname: '/detail',
                                        state: { detail: item }
                                    });
                                }}>
                                </IonImg>
                            </div>
                        </IonSlide>
                    ))}
                </IonSlides>
            </React.Fragment >
        );
    } else if (pageName === 'tvshows') {
        return (
            <React.Fragment>
                <IonSlides id='main-slideshow' class='slide-styles' pager={true} options={slideOpts}>
                    {(getLatestTVShowData as ITVShowData[])?.map((item: ITVShowData, idx: number) => (
                        <IonSlide key={idx}>
                            <div>
                                <div className='slideshow-details'>{t('WatchNowOn')} {item?.source}</div>
                                <IonImg src={item?.poster} alt="images" className='slideshow-images' onClick={(e) => {
                                    e.preventDefault();
                                    history.push({
                                        pathname: '/detail',
                                        state: { detail: item }
                                    });
                                }}>
                                </IonImg>
                            </div>
                        </IonSlide>
                    ))}
                </IonSlides>
            </React.Fragment>
        );
    } else {

        return (
            <React.Fragment>
                <IonSlides id='main-slideshow' class='slide-styles' pager={true} options={slideOpts}>
                    {(combineData as IMovieData[])?.map((item: IMovieData, idx: number) => (
                        <IonSlide key={idx}>
                            <div>
                                <div className='slideshow-details'>{t('WatchNowOn')} {item?.source}</div>
                                <IonImg src={item?.poster} alt="images" className='slideshow-images' onClick={(e) => {
                                    e.preventDefault();
                                    history.push({
                                        pathname: '/detail',
                                        state: { detail: item }
                                    });
                                }}>
                                </IonImg>
                            </div>
                        </IonSlide>
                    ))}
                </IonSlides>
            </React.Fragment>
        );
    }

}
export default Slideshow;
