import { IonContent, IonPage, IonGrid, IonRow, IonCol, IonLabel } from '@ionic/react';
import React from 'react';
import './PrivacyPolicy.css';
import AppToolBar from '../components/ToolBar';
import { useTranslation } from 'react-i18next';
import BingeFooter from '../components/BingeFooter';

const PrivacyPolicy: React.FC = () => {
  const { t } = useTranslation();

  return (
    <IonPage>
      <AppToolBar />
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol class="ion-text-center">
              <IonLabel className='section'>{t('PrivacyPolicy')}</IonLabel>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol class="ion-text-center">
              <img src='assets/icon/iconx310.png' alt='Binge Search logo' className='profile-img' loading='lazy' />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
            <b className='detail-section'>Account</b>
            We collect personal information (only name and email address) from you when you voluntarily provide such information by registering for a Binge Search account, which is optinal. However, we do not share this with anyone. 
            By registering a Binge Search account, you authorize us to send you promotional emails, push notifications from time to time. 
            To stop receiving such promotional emails, please visit my profile section and update the preferences.
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
            <b className='detail-section'>Advertising Partners: Google AdSense</b>
            We have no access to or control over cookies that are used by our advertising partners. 
            You can consult the respective privacy policies of our advertising partners for more detailed information on their practices as well as for instructions about how to opt-out of certain practices.
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
            <b className='detail-section'>Logs</b>
            We receive and record information from your browser when you visit our site, such as your Internet Protocol (IP) address, Username, Email. 
            This information is stored in secure logs and is collected automatically. 
            We use this information to analyze and understand how our site is used and to optimize such usage. However, we do not share this with anyone.
            </IonCol>
          </IonRow>
        </IonGrid>
       
      </IonContent>
      <BingeFooter />
    </IonPage>
  );
};

export default PrivacyPolicy;
