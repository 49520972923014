import React from 'react';
import { createRoot } from 'react-dom/client';
import BingeApp from './BingeApp';
import * as serviceWorker from './serviceWorker';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import en_lang from './i18n/translations/en_lang.json';
import hi_lang from './i18n/translations/hi_lang.json';
import es_lang from './i18n/translations/es_lang.json';
import fr_lang from './i18n/translations/fr_lang.json';

// import { ThemeContext, AuthContext } from './contexts/AuthContext';

const selectedLang = localStorage.getItem('BingeSearchLang');
// console.log(`Selected language found in localStorage - ${selectedLang}`);

i18next
.use(initReactI18next)
.init({
    interpolation: { escapeValue: false },  // React already does escaping // react already safes from xss
    lng: selectedLang ? selectedLang : 'en',
    fallbackLng: 'en',                      // use en if detected lng is not available
    resources: {
        en: {
            translation: en_lang
        },
        hi: {
            translation: hi_lang
        },
        es: {
            translation: es_lang
        },
        fr: {
            translation: fr_lang
        }
    }
});


const rootElement : any = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
    <>
        <I18nextProvider i18n={i18next}>
            <BingeApp />
        </I18nextProvider>
    </>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
