import { IonContent, IonPage, IonRefresher, IonRefresherContent, IonLoading, IonLabel, IonRow, IonCol, IonSlides, IonSlide, IonGrid, IonIcon, IonToast, IonButton, IonFab, IonFabButton } from '@ionic/react';
import React, { useRef, useState } from 'react';
import './Movies.css';
import axios from 'axios';
import AppToolBar from '../components/ToolBar';
import { useTranslation } from 'react-i18next';
import { useBingeAxios, BingeBaseURL } from '../BingeEnvConfig';
import { chevronDownCircleOutline, addCircle, arrowRedoCircleOutline, arrowUpSharp } from 'ionicons/icons';
import { slideOpts } from './Home';
import { IMovieData } from '../interfaces/MovieInterface';
import { useHistory, Link } from 'react-router-dom';
import Slideshow from '../components/Slideshow';
import BingeFooter from '../components/BingeFooter';
import ImageComponent from '../components/ImageComponent';

const Movies: React.FC = () => {
  const contentRef = useRef<HTMLIonContentElement | null>(null);
  const { t } = useTranslation();
  let history = useHistory();
  const token = localStorage.getItem('BingeSearchToken');
  const username = localStorage.getItem('BingeSearchUsername');
  const [showToast1, setShowToast1] = useState(false);
  const [showToast2, setShowToast2] = useState(false);
  const [scrollTop, setScrollTop] = useState(false);

  const [{ data: getActionMovieData, loading: isActionMovieLoading, error: getActionMovieError }, actionRefetch] = useBingeAxios('movies/categories?limit=true&genres=action');
  const [{ data: getRomanticMovieData, loading: isRomanticMovieLoading, error: getRomanticMovieError }, romanticRefetch] = useBingeAxios('movies/categories?limit=true&genres=romance');
  const [{ data: getComedyMovieData, loading: isComedyMovieLoading, error: getComedyMovieError }, comedyRefetch] = useBingeAxios('movies/categories?limit=true&genres=comedy');
  const [{ data: getDramaMovieData, loading: isDramaMovieLoading, error: getDramaMovieError }, dramaRefetch] = useBingeAxios('movies/categories?limit=true&genres=drama');
  const [{ data: getSciFiMovieData, loading: isSciFiMovieLoading, error: getSciFiMovieError }, scifiRefetch] = useBingeAxios('movies/categories?limit=true&genres=sci-fi');
  const [{ data: getHorrorMovieData, loading: isHorrorMovieLoading, error: getHorrorMovieError }, horrorRefetch] = useBingeAxios('movies/categories?limit=true&genres=horror');
  const [{ data: getAnimationMovieData, loading: isAnimationMovieLoading, error: getAnimationMovieError }, animationRefetch] = useBingeAxios('movies/categories?limit=true&genres=animation');
  const [{ data: getCrimeMovieData, loading: isCrimeMovieLoading, error: getCrimeMovieError }, crimeRefetch] = useBingeAxios('movies/categories?limit=true&genres=crime');
  const [{ data: getThrillerMovieData, loading: isThrillerMovieLoading, error: getThrillerMovieError }, thrillerRefetch] = useBingeAxios('movies/categories?limit=true&genres=thriller');
  const [{ data: getAdventureMovieData, loading: isAdventureMovieLoading, error: getAdventureMovieError }, adventureRefetch] = useBingeAxios('movies/categories?limit=true&genres=adventure');

  const [{ data: getTopIMDBMovieData, loading: isTopIMDBMovieLoading, error: getTopIMDBMovieError }, topIMDBMoviesRefetch] = useBingeAxios('movies/topimdbrated?limit=true');

  if (getActionMovieError && getRomanticMovieError && getComedyMovieError && getDramaMovieError && getSciFiMovieError
    && getHorrorMovieError && getAnimationMovieError && getCrimeMovieError && getThrillerMovieError && getAdventureMovieError && getTopIMDBMovieError) {
    return (
      <React.Fragment>
        <IonPage>
          <AppToolBar />
          <IonContent>
            <IonRefresher slot="fixed" onIonRefresh={(actionRefetch || romanticRefetch || comedyRefetch || dramaRefetch || scifiRefetch ||
              horrorRefetch || animationRefetch || crimeRefetch || thrillerRefetch || adventureRefetch || topIMDBMoviesRefetch) as any}>
              <IonRefresherContent
                pullingIcon={chevronDownCircleOutline}
                pullingText="Pull to refresh"
                refreshingSpinner="circles"
                refreshingText="Refreshing...">
              </IonRefresherContent>
            </IonRefresher>

            <div className='error-box'>
              <p>{t('ErrorFetchingContent')}<span role='img' aria-label='sad'>😥</span>. This is due to - {JSON.stringify(getActionMovieError?.message)}.</p>
              <p>{t('FollowInstructions')}:</p>
              <ul>
                <li>{t('CheckInternetConnection')}.</li>
                <li>{t('PullPageRefresh')}.</li>
                <li>{t('RefreshBrowserPage')}.</li>
              </ul>
              <p>{t('TryAgainSometime')}.</p>
            </div>
          </IonContent>
          <BingeFooter />
        </IonPage>
      </React.Fragment>
    );
  }

  if (isActionMovieLoading || isRomanticMovieLoading || isComedyMovieLoading || isDramaMovieLoading || isSciFiMovieLoading || isHorrorMovieLoading
    || isAnimationMovieLoading || isCrimeMovieLoading || isThrillerMovieLoading || isAdventureMovieLoading || isTopIMDBMovieLoading) {
    return (
      <React.Fragment>
        <AppToolBar />
        <IonLoading
          isOpen={isActionMovieLoading || isRomanticMovieLoading || isComedyMovieLoading || isDramaMovieLoading || isSciFiMovieLoading || isHorrorMovieLoading
            || isAnimationMovieLoading || isCrimeMovieLoading || isThrillerMovieLoading || isAdventureMovieLoading || isTopIMDBMovieLoading}
          spinner='bubbles'
          showBackdrop={true}
          message={'Fetching All Movies for you...😍'}
          cssClass='ion-loader-design' />
      </React.Fragment>
    );
  }

  const addToWatchList = async (title: string, type: string) => {
    if (token) {
      const result = await axios({
        method: 'PUT',
        url: BingeBaseURL + 'user/watchlist',
        data: {
          username: JSON.parse(username as string),
          watchlist: {
            title: title,
            type: type
          }
        },
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json;charset=utf-8',
          'Cache-Control': 'no-cache, no-store, must-revalidate', // HTTP 1.1.
          'Pragma': 'no-cache',
          'Expires': '0',
          'Authorization': JSON.parse(token)
        }
      });
      if (result.status === 203) {
        setShowToast2(true);
      } else {
        setShowToast1(true);
      }
    } else {
      history.push('/login');
    }
  }

  // scroll to Top of the page
  const scrollToPageTop = () => {
    contentRef.current && contentRef.current.scrollToTop(800);
  };

  return (
    <IonPage>
      <AppToolBar />
      <IonContent scrollEvents={true} ref={contentRef} onIonScroll={() => { setScrollTop(true) }}>
        <IonToast
          isOpen={showToast1}
          onDidDismiss={() => setShowToast1(false)}
          message="Your favorite has been added to your BingeList!"
          duration={1000}
          position="bottom"
          color='primary'
        />
        <IonToast
          isOpen={showToast2}
          onDidDismiss={() => setShowToast2(false)}
          message="This favorite item already exists. Jump and check again!"
          duration={1000}
          position="bottom"
          color='warning'
        />
        <IonRefresher slot="fixed" onIonRefresh={(actionRefetch || romanticRefetch || comedyRefetch || dramaRefetch || scifiRefetch ||
          horrorRefetch || animationRefetch || crimeRefetch || thrillerRefetch || adventureRefetch || topIMDBMoviesRefetch) as any}>
          <IonRefresherContent
            pullingIcon={chevronDownCircleOutline}
            pullingText="Pull to refresh"
            refreshingSpinner="circles"
            refreshingText="Refreshing...">
          </IonRefresherContent>
        </IonRefresher>

        <IonGrid>
          <IonRow>
            <IonCol>
              <Slideshow pageName="movies" />
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonLabel className='section'>{t('ExploreDramaMovies')}
                <IonButton fill='clear' size='small' routerLink="/movies/list/drama" routerDirection="forward" >
                  <IonIcon className='explore-icon' icon={arrowRedoCircleOutline}></IonIcon>
                </IonButton>
              </IonLabel>
              <IonSlides scrollbar={true} options={slideOpts}>
                {(getActionMovieData as IMovieData[]).map((item: IMovieData, idx: number) => (
                  <IonSlide key={idx} >
                    <IonRow>
                      <IonCol>
                        <Link to={{ pathname: `/movies/${item.title}`, state: { detail: item } }} >
                          <ImageComponent imageUrl={item?.poster} alt="BingeSearchImage" className='round-corner' />
                        </Link>
                        <div className='source-style'>{item?.source}</div>
                      </IonCol>
                    </IonRow>
                  </IonSlide>
                ))}
              </IonSlides>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonLabel className='section'>{t('RomanticDelights')}
                <IonButton fill='clear' size='small' routerLink="/movies/list/romance" routerDirection="forward" >
                  <IonIcon className='explore-icon' icon={arrowRedoCircleOutline}></IonIcon>
                </IonButton>
              </IonLabel>
              <IonSlides scrollbar={true} options={slideOpts}>
                {(getRomanticMovieData as IMovieData[]).map((item: IMovieData, idx: number) => (
                  <IonSlide key={idx} >
                    <IonRow >
                      <IonCol>
                        <IonIcon className='watchlist-icon' icon={addCircle} onClick={() => {
                          addToWatchList(item?.title, item?.type);
                        }}>
                        </IonIcon>
                        <Link to={{ pathname: `/movies/${item.title}`, state: { detail: item } }} >
                          <ImageComponent imageUrl={item?.poster} alt="BingeSearchImage" className='left-corner' />
                        </Link>
                        <div className='source-style'>{item?.source}</div>
                      </IonCol>
                    </IonRow>
                  </IonSlide>
                ))}
              </IonSlides>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonLabel className='section'>{t('TopImdbMovies')}
                <IonButton fill='clear' size='small' routerLink="/movies/list/Top Movies" routerDirection="forward" >
                  <IonIcon className='explore-icon' icon={arrowRedoCircleOutline}></IonIcon>
                </IonButton>
              </IonLabel>
              <IonSlides scrollbar={true} options={slideOpts}>
                {(getTopIMDBMovieData as IMovieData[]).map((item: IMovieData, idx: number) => (
                  <IonSlide key={idx}>
                    <div>
                      <div className='slide-numbering'>{item.rank}</div>
                      <Link to={{ pathname: `/movies/${item.title}`, state: { detail: item } }} >
                        <ImageComponent imageUrl={item?.poster} alt="BingeSearchImage" className='square-corner' />
                      </Link>
                    </div>
                  </IonSlide>
                ))}
              </IonSlides>
            </IonCol>
          </IonRow>

          {/* <IonRow>
            <IonCol className='ad-banner'>
              Ad Banner
            </IonCol>
          </IonRow> */}

          <IonRow>
            <IonCol>
              <IonLabel className='section'>{t('CheckoutActionMovies')}
                <IonButton fill='clear' size='small' routerLink="/movies/list/action" routerDirection="forward" >
                  <IonIcon className='explore-icon' icon={arrowRedoCircleOutline}></IonIcon>
                </IonButton>
              </IonLabel>
              <IonSlides scrollbar={true} options={slideOpts}>
                {(getDramaMovieData as IMovieData[])?.map((item: IMovieData, idx: number) => (
                  <IonSlide key={idx}>
                    <IonRow >
                      <IonCol>
                        <IonIcon className='watchlist-icon' icon={addCircle} onClick={() => {
                          addToWatchList(item?.title, item?.type);
                        }}>
                        </IonIcon>
                        <Link to={{ pathname: `/movies/${item.title}`, state: { detail: item } }} >
                          <ImageComponent imageUrl={item?.poster} alt="BingeSearchImage" className='left-corner' />
                        </Link>
                        <div className='source-style'>{item?.source}</div>
                      </IonCol>
                    </IonRow>
                  </IonSlide>
                ))}
              </IonSlides>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonLabel className='section'>{t('Adventures')}
                <IonButton fill='clear' size='small' routerLink="/movies/list/adventure" routerDirection="forward" >
                  <IonIcon className='explore-icon' icon={arrowRedoCircleOutline}></IonIcon>
                </IonButton>
              </IonLabel>
              <IonSlides scrollbar={true} options={slideOpts}>
                {(getAdventureMovieData as IMovieData[]).map((item: IMovieData, idx: number) => (
                  <IonSlide key={idx}>
                    <IonRow>
                      <IonCol className='ion-no-padding'>
                        <Link to={{ pathname: `/movies/${item.title}`, state: { detail: item } }} >
                          <ImageComponent imageUrl={item?.poster} alt="BingeSearchImage" className='square-corner' />
                        </Link>
                      </IonCol>
                    </IonRow>
                  </IonSlide>
                ))}
              </IonSlides>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonLabel className='section'>{t('Animation')}
                <IonButton fill='clear' size='small' routerLink="/movies/list/drama" routerDirection="forward" >
                  <IonIcon className='explore-icon' icon={arrowRedoCircleOutline}></IonIcon>
                </IonButton>
              </IonLabel>
              <IonSlides scrollbar={true} options={slideOpts}>
                {(getAnimationMovieData as IMovieData[]).map((item: IMovieData, idx: number) => (
                  <IonSlide key={idx}>
                    <div>
                      <div className='slide-numbering'>{item.rank}</div>
                      <Link to={{ pathname: `/movies/${item.title}`, state: { detail: item } }} >
                        <ImageComponent imageUrl={item?.poster} alt="BingeSearchImage" className='square-corner' />
                      </Link>
                    </div>
                  </IonSlide>
                ))}
              </IonSlides>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonLabel className='section'>{t('Comedy')}
                <IonButton fill='clear' size='small' routerLink="/home/list/comedy" routerDirection="forward" >
                  <IonIcon className='explore-icon' icon={arrowRedoCircleOutline}></IonIcon>
                </IonButton>
              </IonLabel>
              <IonSlides scrollbar={true} options={slideOpts}>
                {(getComedyMovieData as IMovieData[]).map((item: IMovieData, idx: number) => (
                  <IonSlide key={idx}>
                    <IonRow>
                      <IonCol className='ion-no-padding'>
                        <Link to={{ pathname: `/home/${item.title}`, state: { detail: item } }} >
                          <ImageComponent imageUrl={item?.poster} alt="BingeSearchImage" className='square-corner' />
                        </Link>
                      </IonCol>
                    </IonRow>
                  </IonSlide>
                ))}
              </IonSlides>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonLabel className='section'>{t('Thriller')}
                <IonButton fill='clear' size='small' routerLink="/movies/list/thriller" routerDirection="forward" >
                  <IonIcon className='explore-icon' icon={arrowRedoCircleOutline}></IonIcon>
                </IonButton>
              </IonLabel>
              <IonSlides scrollbar={true} options={slideOpts}>
                {(getThrillerMovieData as IMovieData[]).map((item: IMovieData, idx: number) => (
                  <IonSlide key={idx}>
                    <IonRow>
                      <IonCol className='ion-no-padding'>
                        <Link to={{ pathname: `/movies/${item.title}`, state: { detail: item } }} >
                          <ImageComponent imageUrl={item?.poster} alt="BingeSearchImage" className='square-corner' />
                        </Link>
                      </IonCol>
                    </IonRow>
                  </IonSlide>
                ))}
              </IonSlides>
            </IonCol>
          </IonRow>


          {/* <IonRow>
            <IonCol className='ad-banner'>
              Ad Banner
  </IonCol>
          </IonRow> */}

          <IonRow>
            <IonCol>
              <IonLabel className='section'>{t('EnjoySciFiMovies')}
                <IonButton fill='clear' size='small' routerLink="/movies/list/sci-fi" routerDirection="forward" >
                  <IonIcon className='explore-icon' icon={arrowRedoCircleOutline}></IonIcon>
                </IonButton>
              </IonLabel>
              <IonSlides scrollbar={true} options={slideOpts}>
                {(getSciFiMovieData as IMovieData[]).map((item: IMovieData, idx: number) => (
                  <IonSlide key={idx} >
                    <IonRow >
                      <IonCol>
                        <IonIcon className='watchlist-icon' icon={addCircle} onClick={() => {
                          addToWatchList(item?.title, item?.type);
                        }}>
                        </IonIcon>
                        <Link to={{ pathname: `/movies/${item.title}`, state: { detail: item } }} >
                          <ImageComponent imageUrl={item?.poster} alt="BingeSearchImage" className='left-corner' />
                        </Link>
                        <div className='source-style'>{item?.source}</div>
                      </IonCol>
                    </IonRow>
                  </IonSlide>
                ))}
              </IonSlides>
            </IonCol>
          </IonRow>


          <IonRow>
            <IonCol>
              <IonLabel className='section'>{t('Crime')}
                <IonButton fill='clear' size='small' routerLink="/movies/list/crime" routerDirection="forward" >
                  <IonIcon className='explore-icon' icon={arrowRedoCircleOutline}></IonIcon>
                </IonButton>
              </IonLabel>
              <IonSlides scrollbar={true} options={slideOpts}>
                {(getCrimeMovieData as IMovieData[]).map((item: IMovieData, idx: number) => (
                  <IonSlide key={idx}>
                    <IonRow>
                      <IonCol className='ion-no-padding'>
                        <Link to={{ pathname: `/movies/${item.title}`, state: { detail: item } }} >
                          <ImageComponent imageUrl={item?.poster} alt="BingeSearchImage" className='square-corner' />
                        </Link>
                      </IonCol>
                    </IonRow>
                  </IonSlide>
                ))}
              </IonSlides>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonLabel className='section'>{t('Horror')}
                <IonButton fill='clear' size='small' routerLink="/movies/list/horror" routerDirection="forward" >
                  <IonIcon className='explore-icon' icon={arrowRedoCircleOutline}></IonIcon>
                </IonButton>
              </IonLabel>
              <IonSlides scrollbar={true} options={slideOpts}>
                {(getHorrorMovieData as IMovieData[]).map((item: IMovieData, idx: number) => (
                  <IonSlide key={idx}>
                    <IonRow>
                      <IonCol className='ion-no-padding'>
                        <Link to={{ pathname: `/movies/${item.title}`, state: { detail: item } }} >
                          <ImageComponent imageUrl={item?.poster} alt="BingeSearchImage" className='square-corner' />
                        </Link>
                      </IonCol>
                    </IonRow>
                  </IonSlide>
                ))}
              </IonSlides>
            </IonCol>
          </IonRow>

          {/* <IonRow>
            <IonCol className='ad-banner'>
              Ad Banner
          </IonCol>*/}

        </IonGrid>

        {scrollTop && <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton className='ion-fab' onClick={() => { scrollToPageTop(); setScrollTop(false); }}>
            <IonIcon icon={arrowUpSharp} />
          </IonFabButton>
        </IonFab>}

        <BingeFooter />
      </IonContent>
    </IonPage>
  );
};
export default Movies;
