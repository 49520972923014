import React, { useState, useEffect } from 'react';
import { IonContent, IonPage, IonLabel, IonLoading, IonGrid, IonRow, IonCol, IonIcon, IonList, IonToast } from '@ionic/react';
import './Profile.css';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import AppToolBar from '../components/ToolBar';
import BingeFooter from '../components/BingeFooter';
import { IUserData } from '../interfaces/UserInterface';
import { useBingeAxios, BingeBaseURL, logOut } from '../BingeEnvConfig';
import { checkmarkCircleOutline, closeCircleOutline } from 'ionicons/icons';
import { IUserReview } from '../interfaces/UserInterface';
import { useTranslation } from 'react-i18next';

const Profile: React.FC = () => {
  let history = useHistory();
  const { t } = useTranslation();
  const [userData, setUserData] = useState<IUserData>();
  const [showLoading, setShowLoading] = useState(false);
  const [showToast1, setShowToast1] = useState(false);
  const token = localStorage.getItem('BingeSearchToken');
  const username = localStorage.getItem('BingeSearchUsername');
  let [error, setError] = React.useState('');
  let errorDiv = error ? <div className="error">{error.toString()}</div> : '';
  const [bingeReviews, setBingeReviews] = useState<IUserReview[]>([]);
  let [noReviewMessage, setNoReviewMessage] = useState(`There are no reviews submitted so far. Watch this space for upcoming reviews!`);

  useEffect(() => {
    // clean up controller
    let isSubscribed = true;
    try {
      getUserDetails();
      getUserReviews();
    } catch (err:any) {
      setShowLoading(false);
    }
    // cancel subscription to useEffect
    return () => { (isSubscribed = false) };
  }, []);

  const getUserDetails = async () => {
    setShowLoading(true);
    const result = await axios({
      method: 'GET',
      url: BingeBaseURL + 'user/profile',
      params: {
        username: JSON.parse(username as string)
      },
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json;charset=utf-8',
        'Cache-Control': 'no-cache, no-store, must-revalidate', // HTTP 1.1.
        'Pragma': 'no-cache',
        'Expires': '0',
        'Authorization': JSON.parse(token as string)
      }
    });
    // console.log(result);
    if (result.status === 200) {
      setShowLoading(false);
      setUserData(result.data[0]);
    }
    // token expiration
    else if (result.status === 204) {
      setShowLoading(false);
      setShowToast1(true);
      logOut();
    }
  }

  const getUserReviews = async () => {
    const result = await axios({
      method: 'GET',
      url: BingeBaseURL + 'user/review',
      params: {
        username: JSON.parse(username as string)
      },
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json;charset=utf-8',
        'Cache-Control': 'no-cache, no-store, must-revalidate', // HTTP 1.1.
        'Pragma': 'no-cache',
        'Expires': '0',
        'Authorization': JSON.parse(token as string)
      }
    });
    // console.log(result);
    setBingeReviews(result.data)
    if (result.status === 200) {
      setNoReviewMessage("");
    }// token expiration
    else if (result.status === 204) {
      logOut();
    }
    else {
      setNoReviewMessage(`There are no reviews submitted so far. Watch this space for upcoming reviews!`);
    }

  }

  const saveUserDetails = async (event: any) => {
    event.preventDefault();
    const { name, phone, country, language, theme, email_subscription, push_subscription } = event.target;
    // console.log(name.value, phone.value, language.value, theme.value, email_subscription.value, push_subscription.value);
    // console.log(error);

    if (error === '') {
      setShowLoading(true);
      try {
        const saveResult = await axios({
          method: 'PUT',
          url: BingeBaseURL + 'user/profile',
          data: {
            username: JSON.parse(username as string),
            name: name?.value,
            phone: phone?.value,
            country: country?.value,
            language: language?.value,
            theme: theme?.value,
            email_subscription: email_subscription?.value === 'true' ? true : false,
            push_subscription: push_subscription?.value === 'true' ? true : false
          },
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json;charset=utf-8',
            'Cache-Control': 'no-cache, no-store, must-revalidate', // HTTP 1.1.
            'Pragma': 'no-cache',
            'Expires': '0',
            'Authorization': JSON.parse(token as string)
          }
        });
        setShowLoading(false);
        // console.log(saveResult);
        if (saveResult.status === 201) {
          setError(saveResult.data);
        }
      } catch (err:any) {
        setError(err);
        setShowLoading(false);
      }
    }
  }

  const handleChange = (event: any) => {
    event.preventDefault();
    const { name, value } = event.target;
    console.log(name, value);

    if (name === 'phone' && value.length !== 10) {
      error = 'Phone Numbers must be exactly 10 characters long!';
      setError(error);
    } else {
      setError('');
    }

  }

  const verifiedDiv = userData?.verified ? <IonIcon className='verified-icon' icon={checkmarkCircleOutline} ></IonIcon> : <IonIcon className='watchlist-icon' icon={closeCircleOutline} ></IonIcon>

  return (
    <IonPage>
      <AppToolBar />

      <IonContent>
        <IonLoading
          isOpen={false}
          backdropDismiss
          spinner='bubbles'
          message={'Logging you to the awesomeness..'}
          cssClass='ion-loader-design'
        />
        <IonToast
          isOpen={showToast1}
          onDidDismiss={() => setShowToast1(false)}
          message="Your session is ended! Please login again."
          duration={5000}
          position="bottom"
          color='warning'
        />
        <IonGrid>
          <IonRow>
            <IonCol class="ion-text-center">
              <IonLabel className='section'>{t('profile')}</IonLabel>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol class="ion-text-center">
              <img src='assets/images/Profile.png' alt='my profile image' className='profile-img' loading='lazy' />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol class="ion-text-center">
              <IonLabel>{userData?.username}</IonLabel>
              {/* {verifiedDiv} */}
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol class="ion-text-center">
              <IonLabel> {errorDiv}</IonLabel>
            </IonCol>
          </IonRow>
          <div className="app">
            <div className='login-container'>
              <form id='user-form' className='account-form' onSubmit={saveUserDetails} noValidate>
                <div className='account-form-fields'>
                  <label htmlFor="name" >Full Name</label>
                  <input id='name' name='name' type='text' defaultValue={userData?.name ?? ''} maxLength={20} onChange={handleChange} />
                  <label htmlFor="phone" >Phone</label>
                  <input id='phone' name='phone' type='number' defaultValue={userData?.phone ?? ''} minLength={10} maxLength={10} onChange={handleChange} />
                  <label htmlFor="country" >Country</label>
                  <input id='country' name='country' type='text' defaultValue={userData?.country ?? ''} maxLength={15} onChange={handleChange} />

                  <label htmlFor="language">Select Language</label>
                  <select className='select-dropdown' id='language' name='language' onChange={handleChange} defaultValue={userData?.language ?? ''}>
                    <option value="en">English</option>
                    <option value="es">Spanish</option>
                    <option value="fr">French</option>
                    <option value="hi">Hindi</option>
                  </select>

                  <label htmlFor="theme" >Choose Theme</label>
                  <div className='form-div' onChange={handleChange} >
                    <input id='theme' name='theme' type='radio' value='Light' defaultChecked
                      onChange={handleChange} /> &nbsp;
                    Light&nbsp;&nbsp;
                    <input id='theme' name='theme' type='radio' value='Dark'
                      onChange={handleChange} /> &nbsp;
                    Dark&nbsp;
                  </div>

                  <label htmlFor="email_subscription">Opt for Email Subscription</label>
                  <div className='form-div' >
                    <input id='email_subscription' name='email_subscription' type='radio' value='true' defaultChecked
                    />&nbsp;
                     On&nbsp;&nbsp;
                  <input id='email_subscription' name='email_subscription' type='radio' value='false'
                      defaultChecked={userData?.email_subscription === false} onChange={handleChange} />&nbsp;
                  Off&nbsp;
                  </div>

                  <label htmlFor="push_subscription">Opt for Push Notifications</label>
                  <div className='form-div' >
                    <input id='push_subscription' name='push_subscription' type='radio' value='true' defaultChecked
                      onChange={handleChange} />&nbsp;
                    On&nbsp;&nbsp;
                    <input id='push_subscription' name='push_subscription' type='radio' value='false'
                      onChange={handleChange} />&nbsp;
                    Off&nbsp;
                  </div>

                </div>
                <button className='btn-submit-form' type='submit'>{t('Save')}</button>
              </form>
            </div>
          </div>
          <IonRow>
            <IonCol class="ion-text-center">
              <IonLabel className='section'>{t('MyReviews')}</IonLabel>
            </IonCol>
          </IonRow>
          <IonList>
            <IonLabel>{noReviewMessage}</IonLabel>
            {bingeReviews && (bingeReviews as IUserReview[])?.map((item: IUserReview, idx: number) => (
              <div className='reviews-block' key={idx}>
                <div className='reviews-title'>{item?.title} </div>
                <div className='reviews-type'>{item?.type}</div>
                <div className='review-comments'> <span className='review-rating'>{item?.rating}/5</span>{item?.review}</div>
              </div>
            ))}
          </IonList>

        </IonGrid>
        <BingeFooter />
      </IonContent>

    </IonPage >
  );

};

export default Profile;