import React, { useState } from 'react';
import { IonHeader, IonPopover, IonToolbar, IonImg, IonIcon, IonMenuButton, IonRow, IonCol, IonButtons, IonList, IonItem } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import './ToolBar.css';
// import { useHistory } from 'react-router-dom';
import { personCircleOutline } from 'ionicons/icons';
import { logOut } from '../BingeEnvConfig';
import { Link } from 'react-router-dom';

const AppToolBar: React.FC = () => {
    const { t } = useTranslation();
    // const [showPopover, setShowPopover] = useState(false);

    const [showPopover, setShowPopover] = useState<{ open: boolean, event: Event | undefined }>({
        open: false,
        event: undefined,
    });

    // let history = useHistory();
    const token = localStorage.getItem('BingeSearchToken');

    const isLoggedIn: any = () => {
        if (token) {
            return (
                <IonList>
                    <IonItem routerLink="/profile" routerDirection="forward" onClick={(e) => setShowPopover({ open: false, event: undefined })}>
                        {t('profile')}
                    </IonItem>
                    <IonItem routerLink="/help" routerDirection="forward" onClick={(e) => setShowPopover({ open: false, event: undefined })}>
                        {t('help')}
                    </IonItem>
                    <IonItem routerLink='/home' routerDirection="forward" onClick={(e) => {
                        logOut();
                        setShowPopover({ open: false, event: undefined });
                    }}>{t('logout')}
                    </IonItem>
                </IonList>
            );
        } else {
            return (
                <IonList>
                    <IonItem routerLink="/login" routerDirection="forward" onClick={(e) => setShowPopover({ open: false, event: undefined })}>
                        {t('login')}/{t('signup')}
                    </IonItem>
                    <IonItem routerLink="/help" routerDirection="forward" onClick={(e) => setShowPopover({ open: false, event: undefined })}>
                        {t('help')}
                    </IonItem>
                </IonList>
            );
        }
    }

    return (
        <React.Fragment>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonRow>
                        <IonCol size="2" className="ion-text-start" >
                            <Link to="/home">
                                <IonImg class="binge-search-logo" src="assets/images/BingeSearchLogo.png" alt='BingeSearchLogo'></IonImg>
                            </Link>
                        </IonCol>
                        <IonCol size="8" className="ion-text-center">
                            <div className="app-title">Binge Search</div>
                            <div className="app-subtitle">{t('subtitle')}</div>
                        </IonCol>
                        <IonCol size="2" className="ion-text-end myaccount-icon">
                            <IonIcon onClick={(e) => setShowPopover({ open: true, event: e.nativeEvent })} icon={personCircleOutline} class="myaccount-icon-rotate"></IonIcon>
                        </IonCol>
                    </IonRow>
                </IonToolbar>
            </IonHeader>
            <IonPopover
                isOpen={showPopover.open}
                event={showPopover.event}
                className='popover-top-right'
                animated={true}
                backdropDismiss={true}
                onDidDismiss={e => setShowPopover({ open: false, event: undefined })}
            >
                {isLoggedIn()}
            </IonPopover>
        </React.Fragment>

    );
};

export default AppToolBar;
