import React, { useState } from 'react';
import { IonSearchbar, IonList, IonItem, IonPopover, IonImg, IonRow, IonCol } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import './SearchBar.css';
import { ISearchHits } from '../interfaces/SearchInterface';
import { IMovieData } from '../interfaces/MovieInterface';
import { useHistory } from 'react-router-dom';
import { BingeBaseURL } from '../BingeEnvConfig';
import ImageComponent from './ImageComponent';


const SearchContainer: React.FC = () => {
    const { t } = useTranslation();
    let history = useHistory();
    const [searchText, setSearchText] = useState('');
    const [searchResults, setSearchResults] = useState<ISearchHits[]>([]);
    const [showLoading, setShowLoading] = useState(true);
    const [showPopover, setShowPopover] = useState<{ open: boolean, event: Event | undefined }>({
        open: false,
        event: undefined,
    });
    // const [itemByTitle, setitemByTitle] = useState<IMovieData>();
    let searchResultsLists;

    const getElasticSearchData = async (searchText: any) => {
        const result = await axios(BingeBaseURL + 'search?searchparam=' + searchText);
        // console.log(result);
        // setSearchResults(result.data.hits.hits);
        setSearchResults(result.data);
        setShowLoading(false);
    };

    // const getParticularItem = async (selectedItem: string) => {
    //     const result = await axios(BingeBaseURL + 'movies/' + selectedItem);
    //     console.log(result);
    //     setitemByTitle(result.data);
    // };

    if (searchResults.length) {
        searchResultsLists =
            <IonList lines="inset" className='search-container'>
                {searchResults.map((item: any, idx: number) => (
                    <IonItem key={idx}>
                        <IonRow onClick={(e) => {
                            e.preventDefault();
                            history.push({
                                pathname: `/home/${item.title}`,
                                state: { detail: item }
                            });
                            setShowPopover({ open: false, event: undefined });
                        }}>
                            {/* <Link to={{ pathname: `/home/${item.title}`, state: { detail: item } }} > */}
                            {/* <IonRow key={idx}> */}
                            <ImageComponent imageUrl={item?.poster} alt="BingeSearchResult" className='search-img' width='200px' />
                            <IonCol>
                                <IonRow>
                                    <IonCol className='results-title'>{item.title}</IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol className='results-type'>{item.type}</IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol className='results-source'>{item.source}</IonCol>
                                </IonRow>
                            </IonCol>
                        </IonRow>
                    </IonItem>
                ))}
            </IonList>
    } else {
        searchResultsLists =
            <IonList lines={'none'}>
                <IonItem>
                    No search results found with "{searchText}".
                </IonItem>
                <IonItem>
                    Don't worry, we shall add "{searchText}" soon, if it exists.
                </IonItem>
                <IonItem>
                    For now search with other titles like "Batman", "Breaking Bad" etc.
                </IonItem>
            </IonList>
    }

    return (
        <React.Fragment>
            <IonSearchbar
                animated={true}
                value={searchText}
                placeholder={t('search-placeholder')}
                className='searchbar-design'
                onIonChange={
                    (e) => {
                        setSearchText(e.detail.value!);
                        // console.log(e);
                        getElasticSearchData(e.detail.value!);
                        setShowPopover({ open: true, event: undefined });
                    }
                }
                debounce={1000}
                inputmode='search'
                autocomplete='on'
                autoCorrect='on'
                spellcheck={true}>
            </IonSearchbar>

            <IonPopover
                isOpen={showPopover.open}
                event={showPopover.event}
                className='search-popover'
                animated={true}
                keyboardClose={false}
                onDidDismiss={e => setShowPopover({ open: false, event: undefined })}
                showBackdrop={false}>
                {/* present search list  */}
                {searchResultsLists}
            </IonPopover>
        </React.Fragment >
    );
};

export default SearchContainer;
