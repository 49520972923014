import { IonContent, IonPage, IonLoading, IonGrid, IonRow, IonCol, IonLabel, useIonViewWillEnter, IonFab, IonFabButton, IonIcon } from '@ionic/react';
import React, { useRef, useState } from 'react';
import { IonInfiniteScroll, IonInfiniteScrollContent } from '@ionic/react';
import './Home.css';
import axios from 'axios';
import AppToolBar from '../components/ToolBar';
import { IMovieData } from '../interfaces/MovieInterface';
import { BingeBaseURL } from '../BingeEnvConfig';
import { Link } from 'react-router-dom';
import { useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import BingeFooter from '../components/BingeFooter';
import { arrowUpSharp } from 'ionicons/icons';
import ImageComponent from '../components/ImageComponent';

const List: React.FC = (props) => {
  // let { from } = location.state || { from: { pathname: "/" } };
  // let login = () => {
  //   fakeAuth.authenticate(() => {
  //     history.replace(from);
  //   });
  // };
  const contentRef = useRef<HTMLIonContentElement | null>(null);
  const { t } = useTranslation();
  const [listData, setListData] = useState<IMovieData[]>([]);
  const [showLoading, setShowLoading] = useState(false);
  const [error, setError] = useState(null);
  const [scrollTop, setScrollTop] = useState(false);
  const [disableInfiniteScroll, setDisableInfiniteScroll] = useState<boolean>(false);

  let match: any = useRouteMatch();
  let categoryName: any = match.params.category;
  // console.log("categoryName", categoryName);

  const itemType = match.url.split('/')[1];
  let URLVariable = BingeBaseURL + itemType + '/categories?genres=' + categoryName;

  if (itemType === 'tvshows') {
    if (match.url.split('/')[3].split(' ')[0] === "Top") {
      URLVariable = BingeBaseURL + 'tvshows' + '/topimdbrated';
    } else {
      URLVariable = BingeBaseURL + 'tvshows' + '/categories?genres=' + categoryName;
    }
  } else if (itemType === 'movies') {
    if (match.url.split('/')[3].split(' ')[0] === "Top") {
      URLVariable = BingeBaseURL + 'movies' + '/topimdbrated';
    } else {
      URLVariable = BingeBaseURL + 'movies' + '/categories?genres=' + categoryName;
    }
  } else {
    if (match.url.split('/')[3].split(' ')[0] === "Top" && match.url.split('/')[3].split(' ')[1] === "Movies") {
      URLVariable = BingeBaseURL + 'movies' + '/topimdbrated';
    } else if (match.url.split('/')[3].split(' ')[0] === "Top" && match.url.split('/')[3].split(' ')[1] === "TVShows") {
      URLVariable = BingeBaseURL + 'tvshows' + '/topimdbrated';
    } else {
      URLVariable = BingeBaseURL + 'movies' + '/categories?genres=' + categoryName;
    }
  }

  const getCategoryList = async () => {
    // setShowLoading(true);
    try {
      const result = await axios({
        method: 'GET',
        url: URLVariable,
      });
      // console.log(result);

      if (result && result.data && result.data.length > 0) {
        // setListData([...listData, ...result.data]);
        setListData([...result.data]);
        setDisableInfiniteScroll(result.data < 10); //false then stop loading
      } else {
        setDisableInfiniteScroll(true);
      }

      setShowLoading(false);
    } catch (err: any) {
      setError(err);
      setShowLoading(false);
    }
  }

  useIonViewWillEnter(async () => {
    await getCategoryList();
  });

  const searchNext = async ($event: CustomEvent<void>) => {
    await getCategoryList();
    ($event.target as HTMLIonInfiniteScrollElement).complete();
  }

  if (error) {
    return (
      <React.Fragment>
        <AppToolBar />
        <IonContent>
          <div className='error-box'>
            <p>{t('ErrorFetchingContent')}<span role='img' aria-label='sad'>😥</span>. This is due to - {JSON.stringify(error)}.</p>
            <p>{t('FollowInstructions')}:</p>
            <ul>
              <li>{t('CheckInternetConnection')}.</li>
              <li>{t('PullPageRefresh')}.</li>
              <li>{t('RefreshBrowserPage')}.</li>
            </ul>
            <p>{t('TryAgainSometime')}.</p>
          </div>
        </IonContent>
      </React.Fragment>
    );
  }

  if (showLoading) {
    return (
      <React.Fragment>
        <AppToolBar />
        <IonLoading
          isOpen={showLoading}
          spinner='bubbles'
          showBackdrop={true}
          message={'Hang tight! Fetching complete list to explore...😍'}
          cssClass='ion-loader-design' />
      </React.Fragment>
    );
  }

  // scroll to Top of the page
  const scrollToPageTop = () => {
    contentRef.current && contentRef.current.scrollToTop(800);
  };

  return (
    <IonPage>
      <AppToolBar />
      <IonContent scrollEvents={true} ref={contentRef} onIonScroll={() => { setScrollTop(true) }}>

        <IonGrid>
          <IonLabel className='section'>{t('ExploreCategory')} - {categoryName}</IonLabel>
          <IonRow>
            {(listData as IMovieData[])?.map((item: IMovieData, idx: number) => (
              <IonCol key={idx} sizeXs='4' sizeSm='3' sizeMd='2'>
                <Link to={{ pathname: `/home/list/category/${item.title}`, state: { detail: item } }} >
                  <ImageComponent imageUrl={item?.poster} alt="BingeSearchImage" className='square-corner' height='120px' width='180px' />
                </Link>
                <div className='source-style'>{item?.source}</div>
              </IonCol>
            ))}
          </IonRow>
        </IonGrid>

        <IonInfiniteScroll threshold="100px" disabled={disableInfiniteScroll}
          onIonInfinite={(e: CustomEvent<void>) => searchNext(e)}>
          <IonInfiniteScrollContent loadingText="Loading more and more awesome content..."> </IonInfiniteScrollContent>
        </IonInfiniteScroll>

        {scrollTop && <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton className='ion-fab' onClick={() => { scrollToPageTop(); setScrollTop(false); }}>
            <IonIcon icon={arrowUpSharp} />
          </IonFabButton>
        </IonFab>}

      </IonContent>
      <BingeFooter />
    </IonPage >
  );
};

export default List;