import React from 'react';
import { IonGrid, IonRow, IonCol, IonFooter, IonToolbar, IonIcon } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import './BingeFooter.css';
import { logoFacebook, logoTwitter, logoLinkedin, logoInstagram } from 'ionicons/icons';

export const fbUrl = 'https://www.facebook.com/bingesearch';
export const twUrl = 'https://twitter.com/binge_search';
export const liUrl = 'https://www.linkedin.com/company/66315209/';
export const igUrl = 'https://www.instagram.com/binge_search/';

const BingeFooter: React.FC = () => {
    const { t } = useTranslation();

    return (
        <IonFooter>
            <IonToolbar>
                <IonGrid>
                    <IonRow>
                        <IonCol className='footer-label ion-text-center'>
                            {t('MadeInIndia')}<span role='img' aria-label='love'>💝</span>!
                        </IonCol>
                        <IonCol className='ion-text-center'>
                            <div>
                                {t('FollowUs')} &nbsp;
                            </div>
                            <div>
                                <span className='facebook-logo-icon' onClick={() => {
                                    window.open(fbUrl, '_blank')
                                }}>
                                    <IonIcon icon={logoFacebook}></IonIcon>
                                </span>
                            &nbsp;
                            <span className='twitter-logo-icon' onClick={() => {
                                    window.open(twUrl, '_blank')
                                }}>
                                    <IonIcon icon={logoTwitter}></IonIcon>
                                </span>
                            &nbsp;
                            <span className='linkedin-logo-icon' onClick={() => {
                                    window.open(liUrl, '_blank')
                                }}>
                                    <IonIcon icon={logoLinkedin}></IonIcon>
                                </span>
                            &nbsp;
                            <span className='instagram-logo-icon' onClick={() => {
                                    window.open(igUrl, '_blank')
                                }}>
                                    <IonIcon icon={logoInstagram}></IonIcon>
                                </span>
                            </div>
                        </IonCol>
                        <IonCol className='ion-text-center'>
                            ©BingeSearch.com 2024
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonToolbar>
        </IonFooter >
    );
};

export default BingeFooter;
