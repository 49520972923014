import { IonContent, IonPage, IonList, IonItem, IonLabel, IonTextarea, IonText, IonRow, IonCol, IonButton, IonToast, IonGrid, IonInput } from '@ionic/react';
import React, { useState } from 'react';
import './Help.css';
import AppToolBar from '../components/ToolBar';
import { useTranslation } from 'react-i18next';
import BingeFooter from '../components/BingeFooter';

const Help: React.FC = () => {
  const { t } = useTranslation();
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [name, setName] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const sendQueryMessage = (e: React.FormEvent) => {
    e.preventDefault();
    if (!message) {
      setMessageError(true);
    } else {
      setMessageError(false);
      setFormSubmitted(true);
      window.open(`mailto:team@bingesearch.com?subject=${subject}&body= ${message} by Name: ${name}`);
      setShowToast(true);
    }
  };

  return (
    <IonPage>
      <AppToolBar />
      <IonContent>

        <IonGrid>
          <IonRow>
            <IonCol class="ion-text-center">
              <IonLabel className='section'>{t('HelpSupport')}</IonLabel>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol class="ion-text-center">
              <img src='assets/icon/iconx310.png' alt='Binge Search logo' className='profile-img' loading='lazy' />
            </IonCol>
          </IonRow>

          <form noValidate onSubmit={sendQueryMessage}>
            <IonList>
              <IonItem>
                <IonLabel position="stacked" color="primary">{t('IssueHeadline')}:</IonLabel>
                <IonInput name="subject" type='text' onIonChange={e => setSubject(e.detail.value!)} required ></IonInput>
              </IonItem>
              <IonItem>
                <IonLabel position="stacked" color="primary">{t('EnterQueryHelpMessage')}:</IonLabel>
                <IonTextarea name="message" value={message} spellCheck={false} autocapitalize="off" rows={6}
                  onIonChange={e => setMessage(e.detail.value!)} required>
                </IonTextarea>
              </IonItem>
              <IonItem>
                <IonLabel position="stacked" color="primary">{t('YourName')}:</IonLabel>
                <IonInput name="name" type='text' onIonChange={e => setName(e.detail.value!)} required></IonInput>
              </IonItem>

              {formSubmitted && messageError && <IonText color="danger">
                <p className="ion-padding-start">
                  {t('QueryHelpMessageRequired')}
                </p>
              </IonText>}
            </IonList>

            <IonRow>
              <IonCol>
                <IonButton type="submit" size="default"> {t('Submit')}</IonButton>
              </IonCol>
            </IonRow>
          </form>

        </IonGrid>

      </IonContent>
      <BingeFooter />

      <IonToast
        isOpen={showToast}
        duration={6000}
        message="Your support request has been sent successfully!"
        onDidDismiss={() => setShowToast(false)}
        color='primary'
      />

    </IonPage>


  );
};

export default Help;
