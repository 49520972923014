import axios from 'axios';
import { makeUseAxios } from 'axios-hooks';

// export const environment: string = 'development';
export const environment: string = 'production';

export const BingeBaseURL: string = (environment === 'production' ? 'https://binge-search-b.onrender.com/api/' : 'http://localhost:8084/api/');

console.log(`Environment found is ${environment}`);

const token = localStorage.getItem('BingeSearchToken');

export const useBingeAxios = makeUseAxios({
    axios: axios.create({
        baseURL: BingeBaseURL,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json;charset=utf-8',
            'Cache-Control': 'no-cache, no-store, must-revalidate', // HTTP 1.1.
            'Pragma': 'no-cache',
            'Expires': '0',
            'Authorization': token ? JSON.parse(token as string) : ""
        }
    }),
});


export const logOut = () => {
    localStorage.removeItem('BingeSearchToken');
    localStorage.removeItem('BingeSearchUsername');
};