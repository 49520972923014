import { IonContent, IonPage, IonGrid, IonRow, IonCol, IonLabel, IonIcon } from '@ionic/react';
import React from 'react';
import './AboutUs.css';
import AppToolBar from '../components/ToolBar';
import { useTranslation } from 'react-i18next';
import BingeFooter, { fbUrl, liUrl, twUrl } from '../components/BingeFooter';
import { logoFacebook, logoInstagram, logoLinkedin, logoTwitter } from 'ionicons/icons';

const AboutUs: React.FC = () => {
  const { t } = useTranslation();

  return (
    <IonPage>
      <AppToolBar />
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol class="ion-text-center">
              <IonLabel className='section'>{t('AboutBingeSearch')}</IonLabel>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol class="ion-text-center">
              <img src='assets/icon/iconx310.png' alt='Binge Search logo' className='profile-img' loading='lazy' />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol><b className='detail-section'>Mission</b>
              {t('BingeSearchDesc1')} - {t('BingeSearchDesc2')}. {t('BingeSearchDesc3')}
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <b className='detail-section'>Search Engine</b>
              Binge Search is a free search engine for video streaming services that offers a complete list of all the movies and series that are currently streaming on all popular platforms.
              </IonCol>
          </IonRow>
          <IonRow>
            <IonCol><b className='detail-section'>Streaming Guide</b>
              We aggregate content from all streaming services in one place exclusively for you.
              We help you search, wishlist, rate/comment and discover the perfect Movie, TV Shows and other Web series to watch online.
            </IonCol>
          </IonRow>
          <IonRow>
            <b className='detail-section'>Download the Android App Now</b>
            <IonCol size='3'>
              <a href='https://play.google.com/store/apps/details?id=com.kialabs.bingesearch&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                <img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' loading='lazy' />
              </a>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <span>
                <b className='detail-section'>Follow us on</b>
              </span>
              &nbsp;
              <span className='facebook-logo-icon-1' onClick={() => {
                window.open(fbUrl, '_blank')
              }}>
                <IonIcon icon={logoFacebook}></IonIcon>
              </span>
                            &nbsp;
               <span className='twitter-logo-icon-1' onClick={() => {
                window.open(twUrl, '_blank')
              }}>
                <IonIcon icon={logoTwitter}></IonIcon>
              </span>
                            &nbsp;
              <span className='linkedin-logo-icon-1' onClick={() => {
                window.open(liUrl, '_blank')
              }}>
                <IonIcon icon={logoLinkedin}></IonIcon>
              </span>
                            &nbsp;
               <span className='instagram-logo-icon-1' onClick={() => {
                window.open(liUrl, '_blank')
              }}>
                <IonIcon icon={logoInstagram}></IonIcon>
              </span>
            </IonCol>
          </IonRow>
        </IonGrid>

      </IonContent>
      <BingeFooter />
    </IonPage>
  );
};

export default AboutUs;
