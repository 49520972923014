import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonToolbar,
  isPlatform,
  IonFabButton,
  IonFab,
  IonFabList,
  IonTitle,
  IonHeader,
} from '@ionic/react';

import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { moon, sunny, listSharp, listOutline, filmSharp, filmOutline, peopleSharp, peopleOutline, language, homeOutline, homeSharp, tvOutline, tvSharp, ribbonOutline, ribbonSharp, briefcaseOutline, briefcaseSharp, informationCircleOutline, informationCircleSharp } from 'ionicons/icons';
import './Menu.css';
import { useTranslation } from 'react-i18next';
import { useBingeLocalStorage } from '../contexts/BingeLocalStorage';

const Menu: React.FC = () => {
  const { i18n, t } = useTranslation();
  const location = useLocation();
  let googlePlayStore;
  const selectedLang = localStorage.getItem('BingeSearchLang');
  const [lang, setLang] = useBingeLocalStorage('BingeSearchLang', selectedLang ? selectedLang : 'en');

  // Use useEffect to update the class on the body element when darkMode changes
  useEffect(() => {
    changeLanguage(lang);
    // Save the current mode to local storage
    localStorage.setItem('BingeSearchLang', lang.toString());
  }, [lang]);

  // Retrieve the mode from local storage on initial render
  const storedMode = localStorage.getItem('BingeSearchDark');
  // Check if the user has a system dark mode preference
  const prefersDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
  // Initialize the mode based on local storage or system preference
  const [darkMode, setDarkMode] = useBingeLocalStorage('BingeSearchDark', storedMode === 'true' || prefersDarkMode);

  // Use useEffect to update the class on the body element when darkMode changes
  useEffect(() => {
    const body = document.body;
    if (darkMode) {
      body.classList.add('dark-mode');
    } else {
      body.classList.remove('dark-mode');
    }

    // Save the current mode to local storage
    localStorage.setItem('BingeSearchDark', darkMode.toString());
  }, [darkMode]);

  // Listen for the toggle check/uncheck to toggle the dark class on the <body>
  const toggleDarkModeHandler = () => {
    setDarkMode(!darkMode);
  };

  // i18n change languages
  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    setLang(lang);
  };

  if (isPlatform('desktop' || 'pwa' || 'mobileweb' || 'mobile')) {
    googlePlayStore =
      <a href='https://play.google.com/store/apps/details?id=com.kialabs.bingesearch&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
        <img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' loading='lazy' />
      </a>
  }

  return (
    /*--  the side menu with same  contentId--*/
    <IonMenu side="start" contentId="bingesearch" type="overlay" >
      <IonHeader>
        <IonToolbar color="primary" >
          <div className='welcome-message'>
            {t('welcome')}
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent>

        <IonMenuToggle autoHide={false}>
          <IonList id="inbox-list" lines={'none'}>
            <IonItem className={location.pathname === '/home' ? 'selected' : ''} routerLink='/home' routerDirection="root" lines="none" detail={false}>
              <IonIcon slot="start" ios={homeOutline} md={homeSharp} />
              <IonLabel>{t('home')}</IonLabel>
            </IonItem>
            <IonItem className={location.pathname === '/movies' ? 'selected' : ''} routerLink='/movies' routerDirection="root" lines="none" detail={false}>
              <IonIcon slot="start" ios={filmOutline} md={filmSharp} />
              <IonLabel>{t('movies')}</IonLabel>
            </IonItem>
            <IonItem className={location.pathname === '/tvshows' ? 'selected' : ''} routerLink='/tvshows' routerDirection="root" lines="none" detail={false}>
              <IonIcon slot="start" ios={tvOutline} md={tvSharp} />
              <IonLabel>{t('tvshows')}</IonLabel>
            </IonItem>
            <IonItem className={location.pathname === '/evergreen' ? 'selected' : ''} routerLink='/evergreen' routerDirection="root" lines="none" detail={false}>
              <IonIcon slot="start" ios={ribbonOutline} md={ribbonSharp} />
              <IonLabel>{t('evergreen')}</IonLabel>
            </IonItem>
            {/* <IonItem className={location.pathname === '/trailers' ? 'selected' : ''} routerLink='/trailers' routerDirection="root" lines="none" detail={false}>
              <IonIcon slot="start" ios={videocamOutline} md={videocamSharp} />
              <IonLabel>{t('trailers')}</IonLabel>
            </IonItem> */}
            <IonItem className={location.pathname === '/bingelist' ? 'selected' : ''} routerLink='/bingelist' routerDirection="root" lines="none" detail={false}>
              <IonIcon slot="start" ios={listOutline} md={listSharp} />
              <IonLabel>{t('watchlist')}</IonLabel>
            </IonItem>
          </IonList>

          <IonList id="inbox-list" lines={'none'}>
            <IonItem className={location.pathname === '/help' ? 'selected' : ''} routerLink='/help' routerDirection="root" lines="none" detail={false}>
              <IonIcon slot="start" ios={informationCircleOutline} md={informationCircleSharp} />
              <IonLabel>{t('support')}</IonLabel>
            </IonItem>
            <IonItem className={location.pathname === '/aboutus' ? 'selected' : ''} routerLink='/aboutus' routerDirection="root" lines="none" detail={false}>
              <IonIcon slot="start" ios={peopleOutline} md={peopleSharp} />
              <IonLabel>{t('aboutus')}</IonLabel>
            </IonItem>
            <IonItem className={location.pathname === '/privacypolicy' ? 'selected' : ''} routerLink='/privacypolicy' routerDirection="root" lines="none" detail={false}>
              <IonIcon slot="start" ios={briefcaseOutline} md={briefcaseSharp} />
              <IonLabel>{t('PrivacyPolicy')}</IonLabel>
            </IonItem>
            <IonItem className='version-label'>
              <IonLabel> {t('version')} 1.3.1</IonLabel>
            </IonItem>
          </IonList>
        </IonMenuToggle>

        <IonItem className='store-img' lines={'none'}>
          {googlePlayStore}
        </IonItem>

        <IonFab vertical="bottom" horizontal="start" class="darkModeIcon">
          <IonFabButton closeIcon={darkMode ? sunny : moon} className='ion-fab' onClick={toggleDarkModeHandler}>
            {darkMode ? <IonIcon icon={sunny} /> : <IonIcon icon={moon} />}
          </IonFabButton>
          <IonFabList side="end">
          </IonFabList>
        </IonFab>

        <IonFab vertical="bottom" horizontal="start">
          <IonFabButton className='ion-fab'>
            <IonIcon icon={language} />
          </IonFabButton>
          <IonFabList side="end" className='ion-fab-list'>
            <IonFabButton color='secondary' className='ion-fab-lang' onClick={() => changeLanguage('en')}>
              <IonTitle>English</IonTitle>
            </IonFabButton>
            <IonFabButton color='secondary' className='ion-fab-lang' onClick={() => changeLanguage('fr')}>
              <IonTitle>French</IonTitle>
            </IonFabButton>
            <IonFabButton color='secondary' className='ion-fab-lang' onClick={() => changeLanguage('es')}>
              <IonTitle>Spanish</IonTitle>
            </IonFabButton>
            <IonFabButton color='secondary' className='ion-fab-lang' onClick={() => changeLanguage('hi')}>
              <IonTitle>Hindi</IonTitle>
            </IonFabButton>
          </IonFabList>
        </IonFab>
      </IonContent>
    </IonMenu >
  );
};

export default Menu;