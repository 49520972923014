import { IonContent, IonPage, IonRow, IonCol, IonLoading } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import './Login.css';
import AppToolBar from '../components/ToolBar';
import BingeFooter from '../components/BingeFooter';
import { useAuthDataContext } from '../contexts/AuthContext';
import { useHistory } from 'react-router-dom';
import { useBingeLocalStorage } from '../contexts/BingeLocalStorage';
import axios from 'axios';
import { BingeBaseURL } from '../BingeEnvConfig';
import { useTranslation } from 'react-i18next';

type IFormInput = {
  username: String;
  fullname?: String;
  password?: String;
  confirmpassword?: String;
  role?: String;
};

const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);

const Login: React.FC = () => {
  const [option, setOption] = React.useState(1);
  let history = useHistory();
  const { t } = useTranslation();
  const [token, setToken] = useBingeLocalStorage('BingeSearchToken', '');
  const [authUsername, setAuthUsername] = useBingeLocalStorage('BingeSearchUsername', '');

  const [loginErrors, setLoginErrors] = useState<IFormInput>({ username: '', fullname: '', password: '', confirmpassword: '', role: '' });
  const [loginLoading, setLoginLoading] = React.useState(false);
  const [error, setError] = React.useState('');

  const FormEvent = [BingeBaseURL + 'auth/login', BingeBaseURL + 'auth/register', BingeBaseURL + 'auth/changepassword'];

  let errorDiv = error ? <div className="error">{error.toString()}</div> : '';

  useEffect(() => {
    // clean up controller
    let isSubscribed = true;
    if (token) {
        console.log(`Token found in Login ${token}`);
    } else {
        console.log(`No Token found in Login`);
    }
    // cancel subscription to useEffect
    return () => { (isSubscribed = false) };
}, [token?.length])

  const registerUser = async (event: any) => {
    event.preventDefault();
    const { username, password, fullname, confirmpassword } = event.target;
    // console.log(username.value, password.value, fullname.value, confirmpassword.value);
    // console.log(loginErrors);

    if (validateForm(loginErrors)) {
      // console.info('Valid Form');
      setLoginLoading(true);
      // register
      if (option === 2) {
        try {
          const registerResult = await axios({
            method: 'POST',
            url: FormEvent[1],
            data: {
              username: username?.value,
              fullname: fullname?.value,
              password: password.value,
              role: 'customer'
            }
          });
          setLoginLoading(false);
          // console.log(registerResult);
          if (registerResult.status === 200) {
            setError('');
            setToken(registerResult.data.token);
            setAuthUsername(registerResult.data.username);
            history.push('/profile');
          } else if (registerResult.status === 203) {
            setError(registerResult.data);
          }
        } catch (err: any) {
          setError(err);
          setLoginLoading(false);
        }
        // forgot password
      } else if (option === 3) {
        try {
          const forgotResult = await axios({
            method: 'PUT',
            url: FormEvent[2],
            data: {
              username: username?.value,
              password: password?.value,
              confirmpassword: confirmpassword?.value
            }
          });
          setLoginLoading(false);
          // console.log(forgotResult);
          if (forgotResult.status === 200) {
            setError('');
            history.push('/profile');
          } else if (forgotResult.status === 203) {
            setError(forgotResult.data);
          }
        } catch (err:any) {
          JSON.stringify(err)
          setError(err);
          setLoginLoading(false);
        }
        // login
      } else {
        try {
          const loginResult = await axios({
            method: 'POST',
            url: FormEvent[0],
            data: {
              username: username?.value,
              password: password?.value
            }
          });
          setLoginLoading(false);
          // console.log(loginResult);
          if (loginResult.status === 200) {
            setError('');
            setToken(loginResult.data.token);
            setAuthUsername(loginResult.data.username);
            history.replace('/profile');
          } else if (loginResult.status === 202) {
            setError(loginResult.data);
          } else if (loginResult.status === 203) {
            setError(loginResult.data);
          }
        } catch (err:any) {
          setError(err);
          setLoginLoading(false);
        }
      }
    } else {
      errorDiv = <div className="error">{t('FormInvalid')}</div>;
    }

  }

  const validateForm = (loginErrors: IFormInput) => {
    let valid = true;
    Object.values(loginErrors).forEach(val => val && (valid = false));
    return valid;
  };

  const handleChange = (event: any) => {
    event.preventDefault();
    const { name, value } = event.target;
    // console.log(name, value);

    switch (name) {
      case 'username':
        loginErrors.username =
          validEmailRegex.test(value) ? '' : 'Email is not valid. Write complete email address!';
        break;
      case 'fullname':
        loginErrors.fullname = value.length < 5 ? 'Full Name must be at least 5 characters long!' : '';
        break;
      case 'password':
        loginErrors.password = value.length < 5 ? 'Password must be at least 5 characters long!' : '';
        break;
      case 'confirmpassword':
        loginErrors.confirmpassword = value.length < 5 ? 'Confirm Password must be at least 5 characters long!' : '';
        break;
      default:
        break;
    }

    setLoginErrors(loginErrors);
  }

  return (
    <IonPage>
      <AppToolBar />
      <IonContent>
        <IonLoading
          isOpen={loginLoading}
          backdropDismiss
          spinner='bubbles'
          message={'Logging you to the awesomeness..'}
          cssClass='ion-loader-design'
        />
        <div className="app">
          <div className='login-container'>
            <header>
              <div className={'header-headings ' + (option === 1 ? 'sign-in' : (option === 2 ? 'sign-up' : 'forgot'))}>
                <span>{t('SignAccount')}</span>
                <span>{t('CreateAccount')}</span>
                <span>{t('ResetPassword')}</span>
              </div>
            </header>
            <IonRow className='options'>
              <IonCol className={option === 1 ? 'active' : ''} onClick={() => { setOption(1); setError('') }}>Sign In</IonCol>
              <IonCol className={option === 2 ? 'active' : ''} onClick={() => { setOption(2); setError('') }}>Sign Up</IonCol>
              <IonCol className={option === 3 ? 'active' : ''} onClick={() => { setOption(3);; setError('') }}>Reset Password</IonCol>
            </IonRow>
            {errorDiv}

            <form className='account-form' onSubmit={registerUser} noValidate>
              <div className={'account-form-fields ' + (option === 1 ? 'sign-in' : (option === 2 ? 'sign-up' : 'forgot'))}>

                <input id='username' name='username' type='email' placeholder='E-mail' required onChange={handleChange}
                />
                {loginErrors?.username && <span className='error'>{loginErrors?.username}</span>}

                <input id='fullname' name='fullname' type='text' placeholder='Full Name' maxLength={20} onChange={handleChange}
                  required={option === 2 ? true : false}
                  disabled={option === 1 || option === 3 ? true : false}
                  style={option === 1 || option === 3 ? { display: 'none' } : {}}
                />
                {loginErrors?.fullname && option === 2 && <span className='error'>{loginErrors?.fullname}</span>}

                <input id='password' name='password' type='password' placeholder='Password' onChange={handleChange}
                  minLength={5} required
                />
                {loginErrors?.password && <span className='error'>{loginErrors?.password}</span>}

                <input id='confirmpassword' name='confirmpassword' type='password' placeholder='Confirm Password' onChange={handleChange}
                  minLength={5}
                  required={option === 3 ? true : false}
                  disabled={option === 1 || option === 2 ? true : false}
                  style={option === 1 || option === 2 ? { display: 'none' } : {}}
                />
                {loginErrors?.confirmpassword && option === 3 && <span className='error'>{loginErrors?.confirmpassword}</span>}
              </div>

              <button className='btn-submit-form' type='submit'>
                {option === 1 ? 'Sign In' : (option === 2 ? 'Sign Up' : 'Reset Password')}
              </button>

              <div className='error'>{t('AllFieldsMandatory')}.</div>
            </form>

          </div>
        </div>
        <BingeFooter />
      </IonContent>

    </IonPage>
  );
};

export default Login;
