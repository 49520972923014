import {
  IonContent, IonPage, IonGrid, IonRow, IonLabel, IonCol, IonImg, IonLoading, IonSlides, IonSlide, IonIcon, IonButton, IonRefresher,
  IonRefresherContent, IonTextarea, IonToast, IonList, IonListHeader, IonFab, IonFabButton
} from '@ionic/react';
import React, { useState, useEffect, useRef } from 'react';
import {
  FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, WhatsappShareButton, WhatsappIcon, LinkedinShareButton, LinkedinIcon
} from "react-share";
import './Detail.css';
import AppToolBar from '../components/ToolBar';
import axios from 'axios';
import { useLocation, useHistory, Redirect } from 'react-router';
import { useTranslation } from 'react-i18next';
import { star, chevronDownCircleOutline, arrowUpSharp } from 'ionicons/icons';
import { IMovieData, IReview } from '../interfaces/MovieInterface';
import { useBingeAxios, BingeBaseURL } from '../BingeEnvConfig';
import BingeFooter from '../components/BingeFooter';
import { Link } from 'react-router-dom';
import ImageComponent from '../components/ImageComponent';

const Detail: React.FC = () => {
  const contentRef = useRef<HTMLIonContentElement | null>(null);
  const { t } = useTranslation();
  const location = useLocation();
  let history = useHistory();
  const token = localStorage.getItem('BingeSearchToken');
  const username = localStorage.getItem('BingeSearchUsername');
  let itemDetails: any = {};
  itemDetails = location.state;
  // console.log(itemDetails);
  const [bingeReviews, setBingeReviews] = useState<IReview[]>([]);
  const [reviewListLength, setReviewListLength] = useState(0);
  let [noReviewMessage, setNoReviewMessage] = useState(`There are no reviews submitted so far. Watch this space for upcoming reviews!`);
  const [showToast1, setShowToast1] = useState(false);
  const [showToast2, setShowToast2] = useState(false);
  const [showToast3, setShowToast3] = useState(false);
  const [showToast4, setShowToast4] = useState(false);
  const [scrollTop, setScrollTop] = useState(false);

  useEffect(() => {
    // clean up controller
    let isSubscribed = true;
    try {
      getItemReviews(itemDetails?.detail?.type);
    } catch (err: any) {
      // console.log(err);
    }
    // cancel subscription to useEffect
    return () => { (isSubscribed = false) };
  }, [reviewListLength]);

  const itemType = itemDetails?.detail?.type === 'Movie' ? 'movies' : 'tvshows';
  const [{ data: getRelatedData, loading: isRelatedLoading, error: getRelatedError }, relatedRefetch] = useBingeAxios(itemType + '/categories?limit=true&genres=' + itemDetails?.detail?.genres.split(',')[0].substring(0, 4));

  if (getRelatedError) {
    return (
      <React.Fragment>
        <AppToolBar />
        <IonContent>
          <IonRefresher slot="fixed" onIonRefresh={(relatedRefetch) as any}>
            <IonRefresherContent
              pullingIcon={chevronDownCircleOutline}
              pullingText="Pull to refresh"
              refreshingSpinner="circles"
              refreshingText="Refreshing...">
            </IonRefresherContent>
          </IonRefresher>
          <div className='error-box'>
            <p>{t('ErrorFetchingContent')}<span role='img' aria-label='sad'>😥</span>. This is due to - {JSON.stringify(getRelatedError?.message)}.</p>
            <p>{t('FollowInstructions')}:</p>
            <ul>
              <li>{t('CheckInternetConnection')}.</li>
              <li>{t('PullPageRefresh')}.</li>
              <li>{t('RefreshBrowserPage')}.</li>
            </ul>
            <p>{t('TryAgainSometime')}.</p>
          </div>
        </IonContent>
      </React.Fragment>
    );
  }

  const getItemReviews = async (type: string) => {
    const result = await axios({
      method: 'GET',
      url: type === 'Movie' ? BingeBaseURL + 'movies/review' : BingeBaseURL + 'tvshows/review',
      params: {
        title: itemDetails?.detail?.title
      }
    });
    // console.log(result);
    if (result.status === 200) {
      setBingeReviews(result.data);
      setReviewListLength(result.data.length);
      setNoReviewMessage("");
    } else if (result.status === 203) {
      setNoReviewMessage(result.data);
    } else {
      setNoReviewMessage(`There are no reviews submitted so far. Watch this space for upcoming reviews!`);
    }
  }

  const calculateBingeRating = () => {
    let sum = bingeReviews?.map((item: any) => item.rating).reduce((a: number, c: number) => a + c, 0);
    let totolItemBingeRating = sum / bingeReviews.length;
    return totolItemBingeRating ? totolItemBingeRating : 0;
  }

  const addToWatchList = async (title: string, type: string) => {
    if (token) {
      const result = await axios({
        method: 'PUT',
        url: BingeBaseURL + 'user/watchlist',
        data: {
          username: JSON.parse(username as string),
          watchlist: {
            title: title,
            type: type
          }
        },
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json;charset=utf-8',
          'Cache-Control': 'no-cache, no-store, must-revalidate', // HTTP 1.1.
          'Pragma': 'no-cache',
          'Expires': '0',
          'Authorization': JSON.parse(token)
        }
      });
      if (result.status === 203) {
        setShowToast2(true);
      } else {
        setShowToast1(true);
      }
    } else {
      history.push('/login');
    }
  }

  const submitReviews = async (event: any) => {
    event.preventDefault();
    const { star, review } = event.target;
    // console.log(star.value, review.value);
    if (token) {
      const result = await axios({
        method: 'PUT',
        url: BingeBaseURL + 'user/review',
        data: {
          username: JSON.parse(username as string),
          binge_review: {
            title: itemDetails?.detail?.title,
            type: itemDetails?.detail?.type,
            rating: star.value ? star.value : 0,
            review: review.value
          }
        },
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json;charset=utf-8',
          'Cache-Control': 'no-cache, no-store, must-revalidate', // HTTP 1.1.
          'Pragma': 'no-cache',
          'Expires': '0',
          'Authorization': JSON.parse(token)
        }
      });
      // console.log(result);
      if (result?.status === 203) {
        setShowToast4(true);
      } else {
        setShowToast3(true);
        setReviewListLength(result.data.length + 1);
      }
    } else {
      history.push('/login');
    }
  }

  // scroll to Top of the page
  const scrollToPageTop = () => {
    contentRef.current && contentRef.current.scrollToTop(800);
  };

  return (
    <IonPage>
      <AppToolBar />
      <IonContent scrollEvents={true} ref={contentRef} onIonScroll={() => { setScrollTop(true) }}>
        <IonLoading
          isOpen={isRelatedLoading}
          backdropDismiss
          spinner='bubbles'
          message={'Fetching all details...'}
          cssClass='ion-loader-design'
        />
        <IonToast
          isOpen={showToast1}
          onDidDismiss={() => setShowToast1(false)}
          message="Your favorite has been added to your BingeList!"
          duration={1000}
          position="bottom"
          color='primary'
        />
        <IonToast
          isOpen={showToast2}
          onDidDismiss={() => setShowToast2(false)}
          message="Your favorite item already exists in the BingeList! Jump and check"
          duration={1500}
          position="bottom"
          color='warning'
        />
        <IonToast
          isOpen={showToast3}
          onDidDismiss={() => setShowToast3(false)}
          message="Cheers!! Your reviews have been submitted successfully."
          duration={1500}
          position="bottom"
          color='primary'
        />
        <IonToast
          isOpen={showToast4}
          onDidDismiss={() => setShowToast4(false)}
          message="You have submitted the same reviews and ratings already. Please change the values and submit it again!"
          duration={1500}
          position="bottom"
          color='warning'
        />

        <IonGrid>

          <IonRow>
            <IonCol sizeXs='12' sizeSm='6' >
              <ImageComponent imageUrl={itemDetails?.detail?.poster} alt="BingeSearchImage" className='detail-img' />
            </IonCol>
            <IonCol sizeXs='12' sizeSm='6'>
              <IonRow><IonCol className='movie-title'>{itemDetails?.detail?.title}</IonCol></IonRow>
              <IonRow className='movie-subtitle'>
                <IonCol>{itemDetails?.detail?.type}</IonCol>
                <IonCol>{itemDetails?.detail?.year}</IonCol>
                <IonCol>{itemDetails?.detail?.rating}</IonCol>
                <IonCol>{itemDetails?.detail?.duration}</IonCol>
              </IonRow>
              <IonRow>
                <IonCol>{itemDetails?.detail?.description}</IonCol>
              </IonRow>
              <IonRow>
                {itemDetails?.detail?.genres && <IonCol><span className='text-headers'>{t('Genres')}: </span>{itemDetails?.detail?.genres}</IonCol>}
              </IonRow>
              <IonRow>
                {itemDetails?.detail?.cast && <IonCol><span className='text-headers'>{t('Cast')}: </span>{itemDetails?.detail?.cast}</IonCol>}
              </IonRow>
              <IonRow>
                {itemDetails?.detail?.director && <IonCol><span className='text-headers'>{t('Director')}: </span>{itemDetails?.detail?.director}</IonCol>}
              </IonRow>
              <IonRow>
                {itemDetails?.detail?.language && <IonCol><span className='text-headers'>{t('Language')}: </span>{itemDetails?.detail?.language}</IonCol>}
              </IonRow>
              <IonRow>
                {itemDetails?.detail?.country && <IonCol><span className='text-headers'>{t('Country')}: </span>{itemDetails?.detail?.country}</IonCol>}
              </IonRow>
              <IonRow>
                {itemDetails?.detail?.tags && (itemDetails?.detail?.tags)?.split(',')?.map((item: string, idx: number) => (
                  <div className='tag' key={idx}>
                    {item}
                  </div>
                ))}
              </IonRow>
              <IonRow>
                <IonCol className='ion-text-center row-padding'>
                  <IonCol>
                    <WhatsappShareButton
                      title={`Checkout ${itemDetails?.detail?.title} on BingeSearch`}
                      url={"https://www.bingesearch.com"}
                      separator=":">
                      <WhatsappIcon size={32} round />
                    </WhatsappShareButton>
                  </IonCol>
                  <IonCol>
                    <FacebookShareButton
                      title={`Checkout ${itemDetails?.detail?.title} on BingeSearch`}
                      url={"https://www.bingesearch.com"}
                      quote={`Checkout ${itemDetails?.detail?.type}-${itemDetails?.detail?.title} on BingeSearch. Plot: ${itemDetails?.detail?.description}`}
                      hashtag={"#bingesearch"}>
                      <FacebookIcon size={32} round />
                    </FacebookShareButton>
                  </IonCol>
                  <IonCol>
                    <TwitterShareButton
                      title={`Checkout ${itemDetails?.detail?.type}-${itemDetails?.detail?.title} on BingeSearch. Plot: ${itemDetails?.detail?.description}`}
                      url={"https://www.bingesearch.com"}
                      via=''
                      related={[]}
                      hashtags={["bingesearch"]}>
                      <TwitterIcon size={32} round />
                    </TwitterShareButton>
                  </IonCol>
                  <IonCol>
                    <LinkedinShareButton
                      title={`Checkout ${itemDetails?.detail?.title} on BingeSearch`}
                      url={"https://www.bingesearch.com"}
                      summary={`Plot: ${itemDetails?.detail?.description}`}
                      source='BingeSearch.com'>
                      <LinkedinIcon size={32} round />
                    </LinkedinShareButton>
                  </IonCol>
                </IonCol>
              </IonRow>

            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol sizeXs='12' sizeSm='6' className='ion-text-center'>
              <IonButton size="small" color='primary' href={itemDetails?.detail?.link} target="_blank">
                {t('WatchNowOn')}&nbsp;{itemDetails?.detail?.source}
              </IonButton>
            </IonCol>
            <IonCol sizeXs='12' sizeSm='6' className='ion-text-center'>
              <IonButton size="small" color='primary' onClick={() => {
                addToWatchList(itemDetails?.detail?.title, itemDetails?.detail?.type);
              }}>
                {t('AddToWatchList')}
              </IonButton>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol sizeXs='12' sizeSm='6' className='ion-text-center row-padding'>
              <span className='text-headers'>{t('ImdbRating')}:</span> <span className='rating-box'>{itemDetails?.detail?.imdb ? itemDetails?.detail?.imdb : 0}/10</span>
            </IonCol>
            <IonCol sizeXs='12' sizeSm='6' className='ion-text-center row-padding'>
              <span className='text-headers'>{t('BingeSearchRating')}:</span> <span className='rating-box'>{calculateBingeRating()}/5</span>
            </IonCol>
          </IonRow>

          <form id='rating-form' onSubmit={submitReviews} noValidate>
            <IonRow>
              <IonCol sizeXs='12' sizeSm='4' className='rating-align'>
                <div className="rating">
                  <input id="star5" name="star" type="radio" value="5" className="radio-btn hide" />
                  <label htmlFor="star5" ><IonIcon icon={star}></IonIcon></label>
                  <input id="star4" name="star" type="radio" value="4" className="radio-btn hide" />
                  <label htmlFor="star4" ><IonIcon icon={star}></IonIcon></label>
                  <input id="star3" name="star" type="radio" value="3" className="radio-btn hide" />
                  <label htmlFor="star3" ><IonIcon icon={star}></IonIcon></label>
                  <input id="star2" name="star" type="radio" value="2" className="radio-btn hide" />
                  <label htmlFor="star2" ><IonIcon icon={star}></IonIcon></label>
                  <input id="star1" name="star" type="radio" value="1" className="radio-btn hide" />
                  <label htmlFor="star1" ><IonIcon icon={star}></IonIcon></label>
                  <div className="clear"></div>
                </div>
              </IonCol>
              <IonCol sizeXs='10' sizeSm='6'>
                <IonTextarea id="review" name="review" placeholder="Give your valueable reviews.." />
              </IonCol>
              <IonCol size='2' className='rating-align'>
                <IonButton type="submit"> {t('Post')}</IonButton>
              </IonCol>
            </IonRow>
          </form>

          <IonRow>
            <IonCol>
              <IonList>
                <IonListHeader className='text-headers'>{t('BingeSearchReviews')}:</IonListHeader>
                <IonLabel>{noReviewMessage}</IonLabel>
                {(bingeReviews as IReview[]).map((item: IReview, idx: number) => (
                  <div className='reviews-block' key={idx}>
                    <div className='review-comments'> <span className='review-rating'>{item?.rating}/5</span>{item?.review}</div>
                    <div className='review-name'>By {item?.username}</div>
                  </div>
                ))}
              </IonList>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonLabel className='section'>{t('RecommendedBasedOn')} {itemDetails?.detail?.title}
                <IonButton fill='clear' size='small' routerLink="/home/list/drama" routerDirection="forward">
                  <IonIcon className='explore-icon'></IonIcon>
                </IonButton>
              </IonLabel>
              <div className='recommended-item'>
                {getRelatedData && (getRelatedData as IMovieData[])?.map((item: IMovieData, idx: number) => (
                  <div>
                    <Link to={{ pathname: `/home/${item.title}`, state: { detail: item } }}>
                      <ImageComponent imageUrl={item?.poster} alt="BingeSearchImage" className='square-corner' width="170px" height="120px" />
                    </Link>
                    <div className='text-style'>{item?.source}</div>
                  </div>
                ))}
              </div>
            </IonCol>
          </IonRow>

          {/* <IonRow>
            <IonCol className='ad-banner'>
              Ad Banner
  </IonCol>
          </IonRow> */}

        </IonGrid>

        {scrollTop && <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton className='ion-fab' onClick={() => { scrollToPageTop(); setScrollTop(false); }}>
            <IonIcon icon={arrowUpSharp} />
          </IonFabButton>
        </IonFab>}

        <BingeFooter />
      </IonContent>

    </IonPage >
  );
};

export default Detail;
