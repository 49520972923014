import { IonPage, } from '@ionic/react';
import React from 'react';
import './PageNotFound.css';
import AppToolBar from '../components/ToolBar';
import BingeFooter from '../components/BingeFooter';
import { useTranslation } from 'react-i18next';

const PageNotFound: React.FC = () => {
  const { t } = useTranslation();
  return (
    <IonPage>
      <AppToolBar />
      <div className='notfound-body'>
        <div id="clouds">
          <div className="cloud x1"></div>
          <div className="cloud x1_5"></div>
          <div className="cloud x2"></div>
          <div className="cloud x3"></div>
          <div className="cloud x4"></div>
          <div className="cloud x5"></div>
        </div>
        <div className='c'>
          <div className='text-404'>404</div>
          <div className='notfound-text '>{t('PageNotFound')} </div>
          <a className='btn' href='/login'> {t('JumpToLogin')}</a>
        </div>
      </div>
      <BingeFooter />
    </IonPage >
  );
};

export default PageNotFound;
